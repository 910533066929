import React from 'react';

import StatusIndicator from 'components/StatusIndicator';
import Button from 'components/Button';

import { ModelShortStatusData, StateEnum } from 'api/CailagateApi/api/client';
import { StateToStatusMap } from 'types/models';
import { StatusType } from 'components/StatusIndicator/types';

import { getStartTimeStatus } from './utils';
import { formatNumberWithPrecision } from 'utils/numbers';
import { t } from 'localization';

import styles from './styles.module.scss';

interface ServiceStatusBarInterface {
  shortStatus?: ModelShortStatusData;
  startService: () => Promise<void>;
  canStartService?: boolean;
  defaultServiceState?: StateEnum;
}

const ServiceStatusBar = ({
  shortStatus,
  startService,
  defaultServiceState = StateEnum.INACTIVE,
}: ServiceStatusBarInterface) => {
  const canStartService =
    shortStatus !== undefined && shortStatus.instances.internalActive + shortStatus.instances.hostingServerActive === 0;

  const { state, startTimeSeconds } = shortStatus || { state: undefined, startTimeSeconds: undefined };
  const { status, label } = StateToStatusMap[state ? state : defaultServiceState];

  const [startTimeLabel, startTime] = getStartTimeStatus(startTimeSeconds);

  const showStartTime = startTimeLabel !== undefined && startTime !== undefined && status !== StatusType.SUCCESS;

  return (
    <div className={styles.serviceStatusBar}>
      <div className={styles.serviceStatusBar__indicator}>
        <StatusIndicator
          data-test-id='Catalog:ServicePage:StatusIndicator:Indicator'
          label-data-test-id='Catalog:ServicePage:StatusIndicator:Label'
          status={status}
          label={t(label)}
          colorizeLabel={status !== StatusType.SECONDARY}
        />
        {showStartTime ? (
          <span className={styles.serviceStatusBar__indicatorLabel}>
            {t(startTimeLabel, { count: startTime || 0, countLabel: formatNumberWithPrecision(startTime || 0, 0) })}
          </span>
        ) : null}
      </div>
      {shortStatus && (
        <div data-test-id='Catalog:ServicePage:ServiceManagement' style={{ display: 'contents' }}>
          {canStartService && (
            <Button data-test-id='Catalog:ServicePage:StartService' color='primary' onClick={startService}>
              {t('Catalog:ServicePage:StartService')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(ServiceStatusBar);
