import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import {
  Dropdown,
  DropdownItem,
  DropdownItemPropsExt,
  DropdownMenu,
  DropdownToggle,
} from '@just-ai/just-ui/dist/Dropdown';
import IconButton from 'components/IconButton';

import { useToggle } from 'utils/hooks';

import styles from './styles.module.scss';

export interface MenuItemInterface extends DropdownItemPropsExt {
  label?: string;
  linkTo?: string;
  link?: string;
  hide?: boolean;
  render?: (closeMenu: () => void) => React.ReactNode;
}

export interface MenuInterface {
  list: MenuItemInterface[];
  toggleDataTestId?: string;
  toggleIconName?: string;
  toggleClassName?: string;
  onToggle?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  toggleRender?: () => React.ReactNode;
  menuClassName?: string;
  direction?: 'up' | 'left' | 'right' | 'down';
}

const Menu = ({
  list,
  onToggle,
  toggleDataTestId,
  toggleClassName,
  toggleRender,
  menuClassName,
  direction = 'left',
  toggleIconName = 'farEllipsisH',
}: MenuInterface) => {
  const [open, , closeMenu, toggle] = useToggle(false);

  const dropdownToggleRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ top?: number; right?: number } | undefined>();

  useEffect(() => {
    if (open && dropdownToggleRef.current) {
      const { width, height } = dropdownToggleRef.current.getBoundingClientRect();
      setPosition({ top: height, right: width });
    }
  }, [open]);

  const handleToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      toggle();
      onToggle?.(event);
    },
    [onToggle, toggle]
  );

  return (
    <Dropdown isOpen={open} toggle={handleToggle} direction={direction}>
      <DropdownToggle tag='div' innerRef={dropdownToggleRef} className={cn(styles.menu__toggle, toggleClassName)}>
        {toggleRender ? (
          toggleRender()
        ) : (
          <IconButton data-test-id={toggleDataTestId} name={toggleIconName} type='button' color='ghost' />
        )}
      </DropdownToggle>
      <DropdownMenu
        className={cn(styles.menu__list, menuClassName)}
        flip={false}
        modifiers={{ offset: { offset: `${position?.top}, -${position?.right}` } }}
      >
        {list.map(({ label, linkTo, link, hide, render: itemRender, ...item }, index) => {
          const render = (
            <DropdownItem key={`${index}${label}`} {...item}>
              {itemRender ? itemRender(closeMenu) : label}
            </DropdownItem>
          );
          if (hide) return null;
          if (link) {
            return (
              <a key={`${index}${label}`} href={link} target='_blank' rel='noreferrer'>
                {render}
              </a>
            );
          }
          if (linkTo)
            return (
              <Link key={`${index}${label}`} to={linkTo}>
                {render}
              </Link>
            );
          return render;
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default React.memo(Menu);
