import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';

import Iframe from 'components/Iframe';

import { useScrollIframeToAnchor } from './utils';
import { useAppContext } from 'contexts/AppContext';
import { useModalsContext } from 'contexts/ModalsContext';
import styles from './styles.module.scss';

const landingPages = {
  tovie: '/staticPages/TovieMLPLandingPage.html',
  immers: '/staticPages/ImmersLandingPage.html',
  default: '/staticPages/LandingPage.html',
};

const LandingPage = function () {
  const [setIframeOnLoad] = useScrollIframeToAnchor();
  const { canonicalHref, isImmers, isTovieMLP } = useAppContext();
  const landingToShow = (() => {
    if (isTovieMLP) {
      return landingPages.tovie;
    }
    if (isImmers) {
      return landingPages.immers;
    }

    return landingPages.default;
  })();

  const { openServiceDevelopmentRequestModal, openDemoRequestModal, openContactRequestModal } = useModalsContext();

  const messageEventHandler = useCallback(
    (event: MessageEvent<any>) => {
      if (event?.data === 'openServiceDevelopmentRequestModal') {
        openServiceDevelopmentRequestModal();
      } else if (event?.data === 'openDemoRequestModal') {
        openDemoRequestModal();
      } else if (event?.data === 'openContactRequestModal') {
        openContactRequestModal();
      }
    },
    [openContactRequestModal, openDemoRequestModal, openServiceDevelopmentRequestModal]
  );

  return (
    <div className={cn('content-page', styles.landingPage)}>
      <Helmet>{canonicalHref && <link rel='canonical' href={canonicalHref} />}</Helmet>
      <Iframe
        wrapClassName={styles.landingPage__iframeContainer}
        src={landingToShow}
        title='landing iframe'
        fullHeight
        onLoadControlled={setIframeOnLoad}
        messageEventHandler={messageEventHandler}
      />
    </div>
  );
};

export default LandingPage;
