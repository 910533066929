import React, { useCallback, useState } from 'react';
import axios from 'axios';

import Button from 'components/Button';

import ProgressBar from 'components/ProgressBar';
import TestingForm from '../TestingForm';
import TesterHeader from './components/TesterHeader';

import { useLoading } from 'utils/hooks';
import useKeyboardAction from 'utils/hooks/useKeyboardAction';

import { useAuthContext } from 'contexts/AuthContext';
import { useDI } from 'contexts/AppContext';
import { ModelInfoData } from 'api/CailagateApi/api/client';

import { TestingFormService } from '../TestingForm/TestingFormService';
import { WidgetComponentProps, WidgetConfigProps, withWidgetLayout } from 'HOC/withWidgetLayout';

import { t } from 'localization';
import { parseError } from 'utils';
import { EditRequestFieldsNames } from '../TestingForm/types';

import ImageResult, { PredictResultImagesType } from './components/ImageResult';
import { TestWidgetContextProviderComponent, useTestWidgetContext } from '../contexts/TestWidgetContext';

import styles from './styles.module.scss';
import { withCaptcha } from 'HOC/withCaptcha';
import { useCaptchaContext } from 'HOC/withCaptcha/contexts/CaptchaContext';

interface ImageTestWidgetInterface {
  serviceData?: ModelInfoData;
  developerMode?: boolean;
}

function ImageTestWidget({ isFullScreen }: WidgetComponentProps) {
  const [predictResultImages, setPredictResultImages] = useState<PredictResultImagesType>();
  const { executeCaptchaAndGetHeader } = useCaptchaContext();

  const [isLoading, , startLoading, endLoading] = useLoading();
  const [isCaptchaLoading, , startCaptchaLoading, endCaptchaLoading] = useLoading();
  const { user } = useAuthContext();

  const { serviceData, formMethods, predictConfigs } = useTestWidgetContext();
  const testingFormService = useDI(TestingFormService);

  const handleSubmit = useCallback(async () => {
    if (!user || !serviceData?.id) return;
    const {
      id: { modelId, accountId },
      timeouts: { predictTimeoutSec },
    } = serviceData;
    try {
      startCaptchaLoading();
      const headers = await executeCaptchaAndGetHeader();
      endCaptchaLoading();
      startLoading();
      formMethods.clearErrors('commonError');
      const result = await testingFormService.submitTest(
        accountId,
        modelId,
        formMethods.getValues(),
        predictTimeoutSec,
        false,
        {
          headers,
        }
      );
      const images = (result.data as any)?.images_base64 as string[][];
      setPredictResultImages({ data: images.flat(1), error: undefined, price: result.price, time: result.time });
    } catch (error: any) {
      if (axios.isAxiosError(error) && error?.response?.status === 500) {
        const { price, time } = testingFormService.getResponseHeaderInfo(error?.response?.headers);
        setPredictResultImages({ error: JSON.stringify(error.response.data), price, time });
      } else {
        formMethods.setError('commonError', { message: parseError(error) });
      }
    }
    endLoading();
  }, [
    endCaptchaLoading,
    endLoading,
    executeCaptchaAndGetHeader,
    formMethods,
    serviceData,
    startCaptchaLoading,
    startLoading,
    testingFormService,
    user,
  ]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  useKeyboardAction({ enable: isEditing, hotKeys: ['ctrl + enter', 'cmd + enter'], action: handleSubmit });

  const watachRequestData = formMethods.watch(EditRequestFieldsNames.request);

  return (
    <>
      <TesterHeader />
      <TestingForm
        formMethods={formMethods}
        predictConfigs={predictConfigs}
        setIsEditing={setIsEditing}
        errors={formMethods.formState.errors}
        editRequestBodyTitle={t('ServicePage:ImageTestWidget:TestingForm:ImageDescription')}
      />
      {isLoading ? (
        <ProgressBar className={styles[`imageTestWidget__submitButton${isFullScreen ? '-fullScreen' : ''}`]} />
      ) : (
        <Button
          color='primary'
          type='submit'
          className={styles[`imageTestWidget__submitButton${isFullScreen ? '-fullScreen' : ''}`]}
          onClick={handleSubmit}
          data-test-id='testerRunButton'
          disabled={!watachRequestData}
          isLoading={isCaptchaLoading}
        >
          {t('ServicePage:ImageTestWidget:GenerateImage')}
        </Button>
      )}
      <ImageResult result={predictResultImages} />
    </>
  );
}

const ImageTestWidgetWithLayout = withCaptcha(withWidgetLayout(ImageTestWidget));

export default React.memo(({ serviceData, developerMode, ...props }: ImageTestWidgetInterface & WidgetConfigProps) => {
  if (!serviceData) return null;
  return (
    <TestWidgetContextProviderComponent serviceData={serviceData} developerMode={developerMode}>
      <ImageTestWidgetWithLayout {...props} />
    </TestWidgetContextProviderComponent>
  );
});
