import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { t } from 'localization';
import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';
import { useCatalogContext } from '../../contexts/CatalogContext';

import styles from './styles.module.scss';

const CATEGORY_DISPLAY_NAME_PREFIX = 'Catalog:Sidebar:Category';

const CatalogSidebar = () => {
  const {
    queryState,
    updateQueryState,
    taskTypeCategories,
    taskTypeFilter: selectedCategoryName,
  } = useCatalogContext();

  const handleSelectCategory = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: string) => {
      event.preventDefault();
      if (queryState?.taskType === value) {
        updateQueryState('taskType', '');
      } else {
        updateQueryState('taskType', value);
      }
    },
    [queryState?.taskType, updateQueryState]
  );

  return (
    <nav className={styles.catalogSidebar}>
      <Link
        to={`/${CATALOG_BASE_PATH}`}
        onClick={event => handleSelectCategory(event, '')}
        className={cn(styles.catalogSidebar__title, !selectedCategoryName && styles.catalogSidebar__link_active)}
      >
        {t('Catalog:Sidebar:Title')}
      </Link>
      {taskTypeCategories.map((category, index) => (
        <Link
          to={`/${CATALOG_BASE_PATH}?taskType=${category.name}`}
          key={`${category.name}${index}`}
          onClick={event => handleSelectCategory(event, category.name)}
          className={cn(
            styles.catalogSidebar__link,
            category.name === selectedCategoryName && styles.catalogSidebar__link_active
          )}
        >
          {t(`${CATEGORY_DISPLAY_NAME_PREFIX}:${category.name}`)}
          {category.modelsCount !== undefined && (
            <span className={styles.catalogSidebar__count}>{` (${category.modelsCount})`}</span>
          )}
        </Link>
      ))}
    </nav>
  );
};

export default React.memo(CatalogSidebar);
