import { createContext, useCallback, useContext, useState } from 'react';

import Spinner from 'components/Spinner';
import { ModelInfoData, PredictConfigData } from 'api/CailagateApi/api/client';

import { MessageDataType, MessageSourceType, MessageType, ResponseData } from '../types';
import { TestingFormMethodsType, useTestingForm } from '../../TestingForm';
import { EditRequestFieldsNames, RequestBodyMode, TestingFormFieldsNamesList } from '../../TestingForm/types';

import { useAppContext } from 'contexts/AppContext';
import { useLoading } from 'utils/hooks';

import { PredictService } from 'services/PredictService';
import { isFormError } from 'utils/form/types';
import { handleFormErrors } from 'utils/form';
import { getPseudoBackendFormValidationError, isJSONParseError } from 'utils/Error';

export type ChatTestWidgetContextType = {
  serviceData: ModelInfoData;
  pushNewMessage: (newMessage: MessageType) => void;
  pushNewClientMessage: (text: string) => void;
  pushNewBotMessage: (message: MessageDataType) => void;
  messages: MessageType[];
  isLoading: boolean;
  handleSendMessage: (text: string, clearText: () => void) => void;
  formMethods: TestingFormMethodsType;
  predictConfigs?: PredictConfigData[];
  developerMode?: boolean;
};

export const ChatTestWidgetContext = createContext({} as ChatTestWidgetContextType);

interface ChatTestWidgetContextProviderProps {
  serviceData: ModelInfoData;
  developerMode?: boolean;
}

export const ChatTestWidgetContextProviderComponent: React.FC<ChatTestWidgetContextProviderProps> = ({
  children,
  serviceData,
  developerMode = false,
}) => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [isLoading, , startLoading, endLoading] = useLoading();
  const [formMethods, isLoadingFormData, predictConfigs] = useTestingForm(serviceData);
  const watchRequestBodyMode = formMethods.watch(EditRequestFieldsNames.requestBodyMode);

  const { diContainer, handleError } = useAppContext();
  const predictService = diContainer.get(PredictService);

  const pushNewMessage = useCallback((newMessage: MessageType) => {
    setMessages(messages => [...messages, newMessage]);
  }, []);

  const pushNewClientMessage = useCallback(
    (text: string, account?: string) => {
      const timestamp = new Date();
      pushNewMessage({
        data: {
          type: 'text',
          text,
        },
        source: MessageSourceType.CLIENTMESSAGE,
        operator: account,
        timestamp: timestamp,
        id: timestamp.toString(),
      });
    },
    [pushNewMessage]
  );

  const pushNewBotMessage = useCallback(
    (message: MessageDataType) => {
      const timestamp = new Date();
      pushNewMessage({
        source: MessageSourceType.BOTMESSAGE,
        timestamp,
        data: message,
        id: timestamp.toString(),
      });
    },
    [pushNewMessage]
  );

  const handleSendMessage = useCallback(
    async (text: string, clearText: () => void) => {
      const {
        id: { modelId, accountId },
        timeouts: { predictTimeoutSec },
      } = serviceData;
      try {
        const dataToSend: any =
          developerMode && watchRequestBodyMode === RequestBodyMode.json
            ? JSON.parse(text)
            : {
                clientId: accountId.toString(),
                input: text,
              };
        startLoading();
        pushNewClientMessage(text);
        clearText();

        let predictResult;
        try {
          const { configMode, config, configId, shouldSetConfig: shouldUseConfig } = formMethods.getValues();
          const { data } = await predictService.predict(
            accountId,
            modelId,
            dataToSend,
            shouldUseConfig,
            configMode,
            config,
            configId,
            predictTimeoutSec
          );
          predictResult = data;
        } catch (error: any) {
          if (isJSONParseError(error)) {
            throw getPseudoBackendFormValidationError(error?.message, `$.config: ${error?.message}`);
          }
          throw error;
        }
        const data = predictResult as ResponseData;
        if (data?.replies?.length) {
          data.replies.forEach(message => pushNewBotMessage(message));
        }
        formMethods.clearErrors();
        formMethods.reset(formMethods.getValues(), { keepDirty: false });
      } catch (error: any) {
        if (isFormError(error?.response?.data) || isJSONParseError(error)) {
          handleFormErrors(error, TestingFormFieldsNamesList, formMethods.setError);
        } else {
          handleError(error);
        }
      }
      endLoading();
    },
    [
      developerMode,
      endLoading,
      formMethods,
      handleError,
      predictService,
      pushNewBotMessage,
      pushNewClientMessage,
      serviceData,
      startLoading,
      watchRequestBodyMode,
    ]
  );

  return (
    <ChatTestWidgetContext.Provider
      value={{
        serviceData,
        pushNewMessage,
        pushNewClientMessage,
        pushNewBotMessage,
        messages,
        isLoading: isLoading || isLoadingFormData,
        handleSendMessage,
        formMethods,
        predictConfigs,
        developerMode,
      }}
    >
      <Spinner show={isLoadingFormData} />
      {children}
    </ChatTestWidgetContext.Provider>
  );
};

export const useChatTestWidgetContext = () => useContext(ChatTestWidgetContext);
