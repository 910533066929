import React from 'react';
import { Icon, IconProps } from '@just-ai/just-ui/dist/Icon';
import cn from 'classnames';

import { prevent } from 'utils/preventDefault';

import styles from './styles.module.scss';

export interface ChipProps {
  text: string;
  className: string;
  onClick?: (tag: React.MouseEvent) => void;
  clickable?: boolean;
  dataTestId?: string;
  style?: React.CSSProperties;
  iconName?: string;
  iconSize?: IconProps['size'];
}

const Chip = ({ text, className, clickable, onClick, dataTestId, iconName, style, iconSize }: ChipProps) => {
  return (
    <div
      className={cn(styles.chip, className, {
        [styles.chip__clickable]: clickable,
      })}
      style={style}
      onClick={prevent(onClick)}
      data-test-id={dataTestId}
    >
      {iconName && <Icon name={iconName} size={iconSize} />}
      <p>{text}</p>
    </div>
  );
};

export default React.memo(Chip) as typeof Chip;
