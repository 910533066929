import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';

import { isDevEnvironment, isImmersEnv, isMlpTovieEnv } from 'utils';
import styles from './styles.module.scss';
import { AppLogger } from 'services/AppLogger';

type ComponentBuildsType = 'frontend' | 'core' | 'gateway';

type BuildInfoType = Record<ComponentBuildsType, string>;
type BuildInfoResponseType = { buildBranch: string; buildDate: string };

const parseDate = (data: BuildInfoResponseType | undefined) => {
  if (!data) return '';
  const buildDate = data?.buildDate || '';
  const buildBranch = data?.buildBranch || '';
  const formattedDate = DateTime.fromISO(buildDate).toFormat('dd.MM.yyyy HH:mm');
  return `${formattedDate.includes('Invalid DateTime') ? buildDate : formattedDate}, ${buildBranch}`;
};

const fetchBuildInfo = async (url: string): Promise<BuildInfoResponseType | undefined> => {
  try {
    const response = await axios.get<BuildInfoResponseType>(url);
    return response.data;
  } catch (error) {
    AppLogger.log({ exception: error });
    return undefined;
  }
};

const BuildInfo = () => {
  const [buildInfo, setBuildInfo] = useState<BuildInfoType | undefined>();

  const getBuildInfo = useCallback(async () => {
    const [frontendData, coreData, gatewayData] = await Promise.all([
      fetchBuildInfo('/version'),
      fetchBuildInfo('/api/mlpcore/version'),
      fetchBuildInfo('/api/mlpgateway/version'),
    ]);

    const frontend = parseDate(frontendData);
    const core = parseDate(coreData);
    const gateway = parseDate(gatewayData);

    setBuildInfo({ frontend, core, gateway });
  }, []);

  useEffect(() => {
    getBuildInfo();
  }, [getBuildInfo]);

  return isDevEnvironment() && !isImmersEnv() && !isMlpTovieEnv() && buildInfo ? (
    <div className={styles.buildInfo}>
      {buildInfo?.frontend && (
        <span>
          <b>front: </b>
          {buildInfo.frontend}
        </span>
      )}
      {buildInfo?.core && (
        <span>
          <b>core: </b>
          {buildInfo?.core}
        </span>
      )}
      {buildInfo?.gateway && (
        <span>
          <b>gateway: </b>
          {buildInfo?.gateway}
        </span>
      )}
    </div>
  ) : null;
};

export default React.memo(BuildInfo);
