import React from 'react';

interface TovieMLPLogoInterface {
  className?: string;
}

const TovieMLPLogo = ({ className }: TovieMLPLogoInterface) => {
  return (
    <svg
      className={className}
      width='205'
      height='26'
      viewBox='0 0 205 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_9507_32195)'>
        <path
          d='M115.324 20.7563V12.7093C115.346 11.962 115.658 11.2527 116.194 10.7318C116.73 10.211 117.449 9.91967 118.197 9.91967C118.944 9.91967 119.662 10.211 120.199 10.7318C120.735 11.2527 121.047 11.962 121.069 12.7093V20.7563H124.211V12.7813C124.209 11.1767 123.57 9.63851 122.435 8.50388C121.301 7.36925 119.763 6.73065 118.158 6.72803C117.319 6.72267 116.488 6.89695 115.723 7.23912C114.957 7.58132 114.273 8.08348 113.717 8.71188C113.16 8.08335 112.475 7.58116 111.708 7.23899C110.941 6.89682 110.11 6.72262 109.27 6.72803C107.665 6.73065 106.127 7.36917 104.992 8.50372C103.857 9.6383 103.218 11.1764 103.215 12.7813V20.7563H106.438V12.7093C106.46 11.9718 106.769 11.2718 107.298 10.7578C107.827 10.2438 108.536 9.9563 109.274 9.9563C110.012 9.9563 110.721 10.2438 111.25 10.7578C111.78 11.2718 112.088 11.9718 112.11 12.7093V20.7563H115.324Z'
          fill='#FF575F'
        />
        <path d='M127.196 20.7562V3.10986H130.323V20.7562H127.196Z' fill='#FF575F' />
        <path
          d='M165.757 6.54895C163.898 6.60208 162.132 7.37933 160.836 8.71509C159.541 10.0509 158.818 11.8396 158.822 13.7003C158.826 15.5611 159.556 17.3468 160.857 18.677C162.158 20.0073 163.927 20.7771 165.788 20.8224C167.216 20.8345 168.614 20.4051 169.79 19.5931V20.762H172.856V13.6831C172.854 11.7979 172.106 9.99017 170.776 8.65382C169.447 7.31747 167.643 6.56089 165.757 6.54895ZM165.8 17.6776C164.951 17.6727 164.125 17.3952 163.445 16.8861C162.765 16.3769 162.267 15.6629 162.023 14.8491C161.779 14.0354 161.803 13.1649 162.091 12.3657C162.379 11.5666 162.916 10.881 163.623 10.41C164.33 9.93886 165.17 9.70713 166.018 9.74886C166.866 9.79058 167.679 10.1036 168.336 10.6418C168.994 11.18 169.461 11.9149 169.669 12.7385C169.877 13.562 169.815 14.4307 169.493 15.2166C169.191 15.9495 168.677 16.5755 168.017 17.014C167.357 17.4526 166.58 17.6836 165.788 17.6776H165.8Z'
          fill='#FF575F'
        />
        <path
          d='M204.392 14.0251C204.418 13.2151 204.317 12.406 204.093 11.627C203.905 10.9138 203.57 10.2476 203.11 9.67122C202.449 8.72536 201.572 7.95166 200.55 7.41498C199.529 6.87833 198.394 6.59431 197.24 6.58679C196.33 6.59624 195.432 6.80112 194.608 7.18757C193.71 7.58084 192.912 8.17307 192.276 8.91961C191.645 9.53463 191.157 10.2803 190.845 11.1041C190.515 11.946 190.337 12.8396 190.317 13.7436C190.34 14.6701 190.518 15.5862 190.845 16.4535C191.213 17.2895 191.723 18.0551 192.353 18.7159C192.967 19.4205 193.744 19.9635 194.616 20.2971C195.499 20.6576 196.445 20.8371 197.399 20.825C198.825 20.8642 200.225 20.4409 201.391 19.6184C202.462 18.8053 203.305 17.7301 203.839 16.4962H200.352C200.04 16.8896 199.646 17.2097 199.196 17.4339C198.618 17.7429 197.971 17.8987 197.316 17.8864C196.392 17.9123 195.487 17.6194 194.754 17.0568C194.078 16.5314 193.701 15.7044 193.553 14.724H204.392V14.0251ZM193.701 12.2177C193.917 11.4306 194.395 10.7406 195.056 10.262C195.664 9.7487 196.442 9.47992 197.238 9.50783C198.218 9.50783 199.045 9.73408 199.649 10.1866C200.343 10.6653 200.828 11.3923 201.003 12.2177H193.701Z'
          fill='#FF575F'
        />
        <path
          d='M181.698 6.7602C183.279 6.75539 184.817 7.28031 186.065 8.25121C187.314 9.22214 188.201 10.5831 188.586 12.1171H185.292C184.895 11.3107 184.237 10.6624 183.424 10.2778C182.612 9.89312 181.693 9.79481 180.817 9.99877C179.942 10.2027 179.161 10.697 178.602 11.4011C178.043 12.1052 177.739 12.9778 177.739 13.8768C177.739 14.7758 178.043 15.6484 178.602 16.3525C179.161 17.0566 179.942 17.5509 180.817 17.7549C181.693 17.9588 182.612 17.8605 183.424 17.4759C184.237 17.0912 184.895 16.4429 185.292 15.6365H188.586C188.241 16.8985 187.552 18.0396 186.595 18.9316C185.638 19.8237 184.451 20.4314 183.168 20.6866C181.885 20.9418 180.556 20.8343 179.331 20.3763C178.106 19.9183 177.032 19.1278 176.231 18.0937C175.43 17.0595 174.933 15.8225 174.795 14.5215C174.658 13.2205 174.886 11.907 175.454 10.7284C176.022 9.54982 176.907 8.55279 178.01 7.84919C179.112 7.14559 180.39 6.76327 181.698 6.74512V6.7602Z'
          fill='#FF575F'
        />
        <path
          d='M0 11.9838C0 8.85033 1.24478 5.84517 3.4605 3.62946C5.67624 1.41373 8.6814 0.168945 11.8149 0.168945H23.6298V11.9838C23.6298 15.1174 22.385 18.1225 20.1693 20.3383C17.9536 22.554 14.9484 23.7988 11.8149 23.7988C8.6814 23.7988 5.67624 22.554 3.4605 20.3383C1.24478 18.1225 0 15.1174 0 11.9838Z'
          fill='#FF575F'
        />
        <path
          d='M12.3804 10.224V17.0113C12.3804 17.7654 12.6846 18.1425 13.5142 18.1425H14.4191V20.9253H13.2326C11.1059 20.9253 9.21051 19.7212 9.21051 17.0038V3.04205H12.3804V10.224Z'
          fill='#F4F4FB'
        />
        <path d='M11.815 7.13983H7.54907V9.75672H11.815V7.13983Z' fill='#F4F4FB' />
        <path
          d='M15.1584 9.84958C16.2218 9.84958 17.084 8.98746 17.084 7.92398C17.084 6.86053 16.2218 5.99841 15.1584 5.99841C14.0949 5.99841 13.2328 6.86053 13.2328 7.92398C13.2328 8.98746 14.0949 9.84958 15.1584 9.84958Z'
          fill='#F4F4FB'
        />
        <path
          d='M40.2212 10.224V17.0113C40.2212 17.7654 40.5252 18.1425 41.3549 18.1425H42.2598V20.9253H41.0733C38.9467 20.9253 37.0513 19.7212 37.0513 17.0038V3.04205H40.2212V10.224Z'
          fill='#2A2A36'
        />
        <path d='M39.6549 7.13983H35.3891V9.75672H39.6549V7.13983Z' fill='#2A2A36' />
        <path
          d='M42.9984 9.84958C44.0618 9.84958 44.9239 8.98746 44.9239 7.92398C44.9239 6.86053 44.0618 5.99841 42.9984 5.99841C41.9349 5.99841 41.0728 6.86053 41.0728 7.92398C41.0728 8.98746 41.9349 9.84958 42.9984 9.84958Z'
          fill='#2A2A36'
        />
        <path
          d='M48.1319 13.7661C48.1319 15.0456 48.5091 16.0285 49.1876 16.7826C49.8639 17.4639 50.7716 17.8384 51.9781 17.8384C52.4943 17.8523 53.0078 17.759 53.4864 17.5644C53.9667 17.3713 54.4008 17.0792 54.7609 16.7072C55.4395 15.9531 55.7438 14.9752 55.7438 13.6907C55.7438 12.5594 55.4395 11.6545 54.6881 10.9028C54.338 10.5377 53.916 10.2493 53.4485 10.0557C52.9821 9.86133 52.4809 9.76466 51.9756 9.77161C51.4574 9.75609 50.9417 9.84866 50.4614 10.0434C49.9808 10.2382 49.5462 10.5309 49.1853 10.9028C48.4861 11.6907 48.1093 12.7129 48.1294 13.7661H48.1319ZM44.8917 13.7661C44.8758 12.8585 45.056 11.9582 45.4194 11.1266C45.7587 10.2539 46.272 9.45938 46.928 8.79123C47.5628 8.12939 48.335 7.61459 49.1902 7.28295C50.042 6.89334 50.9662 6.68693 51.9027 6.67712C52.8091 6.69991 53.7047 6.87936 54.5498 7.20752C55.3867 7.53973 56.1541 8.02573 56.8121 8.64041C57.536 9.2832 58.1033 10.0828 58.4712 10.9782C58.8501 11.8335 59.0553 12.7555 59.0746 13.6907C59.0674 14.6686 58.8885 15.6376 58.5466 16.5539C58.215 17.409 57.7002 18.1813 57.0384 18.8163C56.427 19.5234 55.6495 20.0676 54.7758 20.4C53.8674 20.7691 52.893 20.9487 51.9127 20.9279C50.9811 20.949 50.0559 20.7689 49.2002 20.4C48.3343 20.0133 47.5429 19.4772 46.8626 18.8163C46.2617 18.1199 45.7788 17.3303 45.432 16.4785C45.0575 15.6242 44.8732 14.6987 44.8917 13.7661Z'
          fill='#2A2A36'
        />
        <path
          d='M95.2079 14.1383C95.2354 13.3257 95.1345 12.5137 94.9087 11.7326C94.7219 11.019 94.3869 10.3526 93.9259 9.77683C93.2653 8.83082 92.3878 8.05702 91.3663 7.52032C90.3451 6.98363 89.2099 6.69972 88.0561 6.69238C87.1461 6.70286 86.2487 6.90769 85.4241 7.29319C84.5265 7.68818 83.7293 8.28016 83.0914 9.0252C82.4609 9.64022 81.9726 10.3858 81.6611 11.2097C81.3342 12.0499 81.1555 12.9404 81.1331 13.8417C81.1558 14.769 81.3344 15.6859 81.6611 16.5541C82.0292 17.3898 82.5394 18.1552 83.1694 18.8165C83.7823 19.5211 84.5595 20.0641 85.4316 20.3977C86.3143 20.7591 87.2609 20.9386 88.2144 20.9256C89.6408 20.9646 91.0414 20.5403 92.2065 19.7164C93.2785 18.9058 94.1223 17.8309 94.6549 16.5968H91.1683C90.8566 16.9908 90.4617 17.311 90.012 17.5345C89.4341 17.8435 88.7866 17.9993 88.1315 17.9869C87.2079 18.0129 86.3033 17.72 85.5699 17.1574C84.8939 16.632 84.5167 15.8049 84.3685 14.8246H95.2079V14.1383ZM84.5167 12.3309C84.7335 11.5441 85.2113 10.8545 85.8716 10.3751C86.4814 9.86374 87.2583 9.59522 88.0535 9.62099C89.034 9.62099 89.8611 9.84721 90.4643 10.2997C91.1599 10.7778 91.6451 11.505 91.8194 12.3309H84.5167Z'
          fill='#2A2A36'
        />
        <path d='M78.5789 7.15222H75.4847V20.938H78.5789V7.15222Z' fill='#2A2A36' />
        <path
          d='M77.0686 1.96875C77.3173 1.9662 77.5639 2.01336 77.7941 2.10747C78.0239 2.20158 78.233 2.34074 78.4085 2.51676C78.5836 2.6926 78.7221 2.90125 78.816 3.13077C78.91 3.36026 78.9579 3.60608 78.9566 3.85411C78.9628 4.14085 78.9049 4.42534 78.787 4.68678C78.6691 4.94822 78.4943 5.17998 78.2752 5.36507C78.0561 5.55018 77.7982 5.68392 77.5209 5.75654C77.2434 5.82913 76.9533 5.83876 76.6714 5.78472C76.198 5.69341 75.7761 5.42775 75.4888 5.04027C75.2018 4.6528 75.0705 4.17169 75.1212 3.69211C75.1717 3.21253 75.4003 2.76937 75.7619 2.45026C76.1234 2.13115 76.5916 1.95932 77.0738 1.96875H77.0686Z'
          fill='#2A2A36'
        />
        <path
          d='M69.5974 7.14972L66.3468 16.1517L63.1694 7.14972H59.6829L64.9166 20.9279H67.4605L72.9985 7.14972H69.5974Z'
          fill='#2A2A36'
        />
        <path
          d='M144.495 6.67584C142.64 6.68498 140.863 7.42598 139.552 8.7378C138.24 10.0496 137.499 11.8262 137.49 13.6813V25.7928H140.498V19.4766C141.403 20.1064 142.444 20.514 143.537 20.6665C144.63 20.8189 145.743 20.712 146.786 20.3542C147.83 19.9964 148.775 19.398 149.544 18.6072C150.313 17.8165 150.885 16.8557 151.214 15.8026C151.543 14.7496 151.619 13.634 151.437 12.546C151.254 11.4581 150.818 10.4284 150.163 9.54037C149.509 8.65236 148.654 7.93095 147.669 7.43458C146.684 6.93821 145.596 6.68084 144.493 6.68328L144.495 6.67584ZM144.495 17.6738C143.576 17.6765 142.685 17.3604 141.974 16.7793C141.262 16.1982 140.774 15.3882 140.593 14.4875C140.412 13.5867 140.55 12.6512 140.982 11.8404C141.414 11.0296 142.113 10.3938 142.962 10.0416C143.81 9.6894 144.755 9.64255 145.634 9.90903C146.513 10.1755 147.273 10.7389 147.783 11.5029C148.293 12.2669 148.522 13.1843 148.431 14.0985C148.341 15.0127 147.935 15.8671 147.285 16.5158C146.543 17.2527 145.541 17.6686 144.495 17.6738Z'
          fill='#FF575F'
        />
        <path d='M153.64 20.7562V3.10986H156.767V20.7562H153.64Z' fill='#FF575F' />
      </g>
      <defs>
        <clipPath id='clip0_9507_32195'>
          <rect width='204.396' height='26' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(TovieMLPLogo);
