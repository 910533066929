import { CatalogCategoryType } from './types';

export const ITEMS_PER_PAGE = 1000;
export const ITEMS_PER_PAGE_SHORT = 30;
export const PAGE_RANGE_TO_DISPLAY = 7;
export const ONLY_PUBLIC_MODELS = true;
export const ITEMS_PER_CAROUSEL_SLIDE = 3;
export const FAVORITE_DEBOUNCE_TIME = 300;

export const CATALOG_FILTER_STORAGE_KEY = 'catalogListPageQuery';

export const baseTaskTypesCategories: CatalogCategoryType[] = [
  { name: 'chat-completion' },
  { name: 'text2vec' },
  { name: 'text-classification' },
  { name: 'text-processing' },
  { name: 'asr' },
  { name: 'tts' },
  { name: 'image-generation' },
  { name: 'ner' },
  { name: 'chat' },
  { name: 'custom' },
];
