import { KeeperInterface, StorageKeeper } from 'services/StorageKeeper';

export class QueryStorageKeeper<VALUE_TYPE extends string> implements KeeperInterface {
  storageKeeper!: StorageKeeper;

  constructor(storageKey: string) {
    this.storageKeeper = new StorageKeeper(storageKey);
  }

  public async get() {
    const data = await this.storageKeeper.get();
    if (!data.success || !data.payload) return undefined;
    try {
      return JSON.parse(data.payload) as Record<VALUE_TYPE, string>;
    } catch (error) {
      return undefined;
    }
  }

  public set(data: Partial<Record<VALUE_TYPE, string>>) {
    this.storageKeeper.set(JSON.stringify(data));
  }

  public setKey(key: string) {
    this.storageKeeper.setKey(key);
  }

  public clear() {
    this.storageKeeper.clear();
  }
}
