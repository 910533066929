import React, { useCallback, useMemo, useState } from 'react';
import axios from 'axios';

import Button from 'components/Button';
import TesterResponse from './components/TesterResponse';
import ProgressBar from 'components/ProgressBar';
import TestingForm from '../TestingForm';
import TesterHeader from './components/TesterHeader';

import { useLoading } from 'utils/hooks';
import useKeyboardAction from 'utils/hooks/useKeyboardAction';

import { useAuthContext } from 'contexts/AuthContext';
import { ModelInfoData } from 'api/CailagateApi/api/client';

import { TestingFormService } from '../TestingForm/TestingFormService';
import { WidgetComponentProps, WidgetConfigProps, withWidgetLayout } from 'HOC/withWidgetLayout';

import { t } from 'localization';
import { decodeBase64ToArrayBuffer, parseError } from 'utils';
import { TTSTestResultType } from './types';
import { PredictResultType } from '../PredictResult/types';
import { EditRequestFieldsNames, RequestBodyMode } from '../TestingForm/types';

import { TestWidgetContextProviderComponent, useTestWidgetContext } from '../contexts/TestWidgetContext';
import { withCaptcha } from 'HOC/withCaptcha';
import { useCaptchaContext } from 'HOC/withCaptcha/contexts/CaptchaContext';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';
import { useDI } from 'contexts/AppContext';

import styles from './styles.module.scss';
import './style.scss';

interface TesterInterface {
  serviceData?: ModelInfoData;
  developerMode?: boolean;
}

function Tester({ isFullScreen }: WidgetComponentProps) {
  const [predictResult, setPredictResult] = useState<PredictResultType | undefined>();
  const { executeCaptchaAndGetHeader } = useCaptchaContext();
  const [isLoading, , startLoading, endLoading] = useLoading();
  const [isCaptchaLoading, , startCaptchaLoading, endCaptchaLoading] = useLoading();
  const { user } = useAuthContext();
  const { serviceData, formMethods, predictConfigs } = useTestWidgetContext();
  const taskType = serviceData.taskType as ServiceTaskTypes;

  const isTTS = useMemo(() => taskType === 'tts', [taskType]);
  const forceRequestBodyMode = useMemo(
    () => (taskType === 'chat-completion' || taskType === 'custom' ? RequestBodyMode.json : undefined),
    [taskType]
  );

  const testingFormService = useDI(TestingFormService);

  const handleSubmit = useCallback(async () => {
    if ((!user && !serviceData.publicSettings.publicTestingAllowed) || !serviceData?.id) return;
    const {
      id: { modelId, accountId },
      timeouts: { predictTimeoutSec },
    } = serviceData;

    try {
      startCaptchaLoading();
      const headers = await executeCaptchaAndGetHeader();
      endCaptchaLoading();
      startLoading();
      formMethods.clearErrors('commonError');
      const { price, time, data } = await testingFormService.submitTest(
        accountId,
        modelId,
        formMethods.getValues(),
        predictTimeoutSec,
        isTTS,
        {
          headers,
        }
      );
      if (isTTS) {
        const audio = (data as unknown as TTSTestResultType)?.audio_base64;
        const audioBuffer = decodeBase64ToArrayBuffer(audio);
        const url = URL.createObjectURL(new Blob([audioBuffer], { type: 'audio/wav' }));
        setPredictResult({ data: url, price, time });
      } else {
        setPredictResult({ data: JSON.stringify(data), price, time });
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error?.response?.status === 500) {
        const { price, time } = testingFormService.getResponseHeaderInfo(error?.response?.headers);
        setPredictResult({ data: JSON.stringify(error.response.data), withError: true, price, time });
      } else {
        formMethods.setError('commonError', { message: parseError(error) });
      }
    }
    endCaptchaLoading();
    endLoading();
  }, [
    endCaptchaLoading,
    endLoading,
    executeCaptchaAndGetHeader,
    formMethods,
    isTTS,
    serviceData,
    startCaptchaLoading,
    startLoading,
    testingFormService,
    user,
  ]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  useKeyboardAction({ enable: isEditing, hotKeys: ['ctrl + enter', 'cmd + enter'], action: handleSubmit });

  const watachRequestData = formMethods.watch(EditRequestFieldsNames.request);

  return (
    <>
      <TesterHeader />
      <TestingForm
        formMethods={formMethods}
        predictConfigs={predictConfigs}
        setIsEditing={setIsEditing}
        errors={formMethods.formState.errors}
        isTTS={isTTS}
        forceRequestBodyMode={forceRequestBodyMode}
        editRequestBodyTitle={
          isTTS ? t('ServicePage:TestingForm:TtsRequestTitle') : t('ServicePage:TestingForm:Request')
        }
      />
      {isLoading ? (
        <ProgressBar className={styles[`tester__submitButton${isFullScreen ? '-fullScreen' : ''}`]} />
      ) : (
        <Button
          color='primary'
          type='submit'
          className={styles[`tester__submitButton${isFullScreen ? '-fullScreen' : ''}`]}
          onClick={handleSubmit}
          data-test-id='testerRunButton'
          disabled={!watachRequestData}
          isLoading={isCaptchaLoading}
        >
          {!isTTS ? t('ServicePage:TestingForm:RunTest') : t('ServicePage:TestingForm:Synthesize')}
        </Button>
      )}
      <TesterResponse result={predictResult} isTTS={isTTS} />
    </>
  );
}

const TesterWithLayoutAndCaptcha = withCaptcha(withWidgetLayout(Tester));

export default React.memo(({ serviceData, developerMode, ...props }: TesterInterface & WidgetConfigProps) => {
  if (!serviceData) return null;
  return (
    <TestWidgetContextProviderComponent serviceData={serviceData} developerMode={developerMode}>
      <TesterWithLayoutAndCaptcha {...props} />
    </TestWidgetContextProviderComponent>
  );
});
