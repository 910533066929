import { RouteProps } from 'react-router-dom';

import CatalogListPage from 'modules/ServicesCatalog/pages/NewCatalogListPage';
import ServicePage from 'modules/ServicesCatalog/pages/ServicePage';

export type RouteConfigType = Omit<RouteProps, 'children'>;
export const routesConfig: RouteConfigType[] = [
  {
    path: '',
    component: CatalogListPage,
    exact: true,
  },
  {
    path: ':account/:service',
    component: ServicePage,
  },
];

export const CATALOG_BASE_PATH = 'catalog';
export const DEFAULT_PATH = '';
