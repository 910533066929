import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';
import ControlledResizableTextArea from 'components/ControlledResizableTextArea';

import { ContactRequestFormFieldsType, ContactRequestFormMethodsType, defaultFormValues } from './types';

import { t } from 'localization';
import styles from './styles.module.scss';
import Container from 'typedi';
import { tokens } from 'diConfig';

interface ContactRequestFormInterface {
  formMethods: ContactRequestFormMethodsType;
}

export const useContactRequestForm = (
  props?: UseFormProps<ContactRequestFormFieldsType>
): UseFormReturn<ContactRequestFormFieldsType, any> => {
  return useForm<ContactRequestFormFieldsType>({
    criteriaMode: 'all',
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
    ...props,
  });
};

export default function ContactRequestForm({ formMethods }: ContactRequestFormInterface) {
  const {
    control,
    formState: { errors },
  } = formMethods;
  const tgLink = Container.get(tokens.TELEGRAM_SUPPORT_LINK);

  return (
    <FormContainer
      className={styles.contactRequestForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      <span>
        {t('ContactRequestModal:Message')}{' '}
        <a target='_blank' rel='noreferrer noopener' href={tgLink}>
          {t('ContactRequestModal:MessageTelegram')}
        </a>
      </span>
      <ControlledInput
        title={t('ContactRequestModal:Form:Name')}
        data-test-id='ContactRequestModal:Form:name'
        name='name'
        placeholder={t('ContactRequestModal:Form:NamePlaceholder')}
        control={control}
      />

      <div className={styles.contactRequestForm__row}>
        <ControlledResizableTextArea
          title={t('ContactRequestModal:Form:Contacts')}
          data-test-id='ContactRequestModal:Form:contacts'
          name='contacts'
          placeholder={t('ContactRequestModal:Form:ContactsPlaceholder')}
          control={control}
        />
      </div>
      <div className={styles.contactRequestForm__row}>
        <ControlledResizableTextArea
          title={t('ContactRequestModal:Form:Question')}
          name='question'
          data-test-id='ContactRequestModal:Form:question'
          control={control}
          placeholder={t('ContactRequestModal:Form:QuestionPlaceholder')}
          className={styles.modelEditForm__textarea}
        />
      </div>
    </FormContainer>
  );
}
