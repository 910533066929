import React from 'react';
import Button from 'components/Button';
import { t } from 'localization';
import styles from './styles.module.scss';
import PredictResult from 'modules/ServiceTestComponents/PredictResult';
import { downloadBase64File } from 'utils';
import { PredictResultType } from 'modules/ServiceTestComponents/PredictResult/types';

const IMAGE_FORMAT = 'png';
const FILE_NAME = 'newImage';

export interface PredictResultImagesType extends Omit<PredictResultType, 'data' | 'withError'> {
  data?: string[];
  error?: string;
}

interface ImageResultInterface {
  result?: PredictResultImagesType;
}

const handleDownloadImage = (image: string) => {
  downloadBase64File(image, `${FILE_NAME}.${IMAGE_FORMAT}`, `image/${IMAGE_FORMAT}`);
};

const ImageResult = ({ result }: ImageResultInterface) => {
  if (result && result?.error) {
    return <PredictResult result={{ data: result.error, withError: true, price: result.price, time: result.time }} />;
  }
  if (!result?.data?.length) return null;
  return (
    <>
      {result.data.map((image, index) => (
        <div className={styles.imageWrap} key={index}>
          <Button
            className={styles.imageWrap__download}
            asLink
            iconLeft='farArrowToBottom'
            data-test-id='downloadImage'
            onClick={() => handleDownloadImage(image)}
          >
            {t('Image:DownloadButton')}
          </Button>
          <img src={`data:image/${IMAGE_FORMAT};base64,${image}`} alt='result' />
        </div>
      ))}
    </>
  );
};

export default React.memo(ImageResult);
