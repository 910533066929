import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';

import {
  ServiceDevelopmentRequestFormFieldsType,
  ServiceDevelopmentRequestFormMethodsType,
  defaultFormValues,
} from './types';

import { t } from 'localization';
import styles from './styles.module.scss';
import ControlledResizableTextArea from 'components/ControlledResizableTextArea';
import Container from 'typedi';
import { tokens } from 'diConfig';

interface ServiceDevelopmentRequestFormInterface {
  formMethods: ServiceDevelopmentRequestFormMethodsType;
}

export const useServiceDevelopmentRequestForm = (
  props?: UseFormProps<ServiceDevelopmentRequestFormFieldsType>
): UseFormReturn<ServiceDevelopmentRequestFormFieldsType, any> => {
  return useForm<ServiceDevelopmentRequestFormFieldsType>({
    criteriaMode: 'all',
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
    ...props,
  });
};

export default function ServiceDevelopmentRequestForm({ formMethods }: ServiceDevelopmentRequestFormInterface) {
  const {
    control,
    formState: { errors },
  } = formMethods;
  const tgLink = Container.get(tokens.TELEGRAM_SUPPORT_LINK);
  return (
    <FormContainer
      className={styles.serviceDevelopmentRequestForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      <span>
        {t('ServiceDevelopmentRequestModal:Message')}{' '}
        <a target='_blank' rel='noreferrer noopener' href={tgLink}>
          {t('ServiceDevelopmentRequestModal:MessageTelegram')}
        </a>
      </span>
      <ControlledInput
        title={t('ServiceDevelopmentRequestModal:Form:Name')}
        data-test-id='ServiceDevelopmentRequestModal:Form:name'
        name='name'
        placeholder={t('ServiceDevelopmentRequestModal:Form:NamePlaceholder')}
        control={control}
      />

      <div className={styles.serviceDevelopmentRequestForm__row}>
        <ControlledResizableTextArea
          title={t('ServiceDevelopmentRequestModal:Form:Contacts')}
          data-test-id='ServiceDevelopmentRequestModal:Form:contacts'
          name='contacts'
          placeholder={t('ServiceDevelopmentRequestModal:Form:ContactsPlaceholder')}
          control={control}
        />
      </div>

      <div className={styles.serviceDevelopmentRequestForm__row}>
        <ControlledResizableTextArea
          title={t('ServiceDevelopmentRequestModal:Form:Comment')}
          data-test-id='ServiceDevelopmentRequestModal:Form:comment'
          name='comment'
          placeholder={t('ServiceDevelopmentRequestModal:Form:CommentPlaceholder')}
          control={control}
        />
      </div>
    </FormContainer>
  );
}
