import { UseFormReturn } from 'react-hook-form';

export type ContactRequestFormMethodsType = UseFormReturn<ContactRequestFormFieldsType, any>;

export enum ContactRequestFormFieldsNames {
  name = 'name',
  question = 'question',
  contacts = 'contacts',
}

export const ContactRequestFormFieldsNamesList = Object.values(ContactRequestFormFieldsNames);

export type ContactRequestFormFieldsType = {
  [ContactRequestFormFieldsNames.name]: string;
  [ContactRequestFormFieldsNames.contacts]: string;
  [ContactRequestFormFieldsNames.question]: string;
  commonError: any;
};
export const defaultFormValues: ContactRequestFormFieldsType = {
  [ContactRequestFormFieldsNames.name]: '',
  [ContactRequestFormFieldsNames.contacts]: '',
  [ContactRequestFormFieldsNames.question]: '',
  commonError: undefined,
};
