import { createContext, FC, useContext, useEffect, useState } from 'react';

import { ModelInfoData, PredictConfigData } from 'api/CailagateApi/api/client';

import { SpinnerWithFade } from 'components/Spinner';

import { formDefaulValues, TestingFormFieldsType } from '../TestingForm/types';
import { TestingFormMethodsType, useTestingForm } from '../TestingForm';
import { getRequestBodyModeByServiceType } from '../utils';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';

export type TestWidgetContextType = {
  serviceData: ModelInfoData;
  formMethods: TestingFormMethodsType;
  predictConfigs?: PredictConfigData[];
  developerMode?: boolean;
};

export const TestWidgetContext = createContext({} as TestWidgetContextType);

interface TestWidgetContextProviderProps {
  serviceData: ModelInfoData;
  developerMode?: boolean;
}

export const TestWidgetContextProviderComponent: FC<TestWidgetContextProviderProps> = ({
  children,
  serviceData,
  developerMode,
}) => {
  const defaultRequestBodyMode = getRequestBodyModeByServiceType(serviceData.taskType as ServiceTaskTypes);
  const [savedTestingFormValues, saveTestingForm] = useState<TestingFormFieldsType | undefined>({
    ...formDefaulValues,
    requestBodyMode: defaultRequestBodyMode || formDefaulValues.requestBodyMode,
  });

  const [formMethods, isLoadingFormData, predictConfigs] = useTestingForm(
    serviceData,
    savedTestingFormValues,
    defaultRequestBodyMode
  );

  useEffect(() => {
    return () => {
      saveTestingForm(formMethods.getValues());
      formMethods.reset(formDefaulValues);
    };
  }, [formMethods, saveTestingForm]);

  return (
    <TestWidgetContext.Provider
      value={{
        serviceData,
        formMethods,
        predictConfigs,
        developerMode,
      }}
    >
      <SpinnerWithFade show={isLoadingFormData} />
      {children}
    </TestWidgetContext.Provider>
  );
};

export const useTestWidgetContext = () => useContext(TestWidgetContext);
