import React, { DependencyList, useCallback, useState, useRef } from 'react';

type PromiseCallbackInterface<DATA, OPEN_PROPS> = {
  (resolve: (data?: DATA) => void, reject: () => void, opened: boolean, props?: OPEN_PROPS): React.ReactNode;
};

interface PromiseHandlersInterface<DATA> {
  onResolve?: (data: DATA) => void;
  onReject?: () => void;
}
const emptyObj = {};

export function usePromisifyComponent<RESULT, OPEN_PROPS = any>(
  callback: PromiseCallbackInterface<RESULT, OPEN_PROPS>,
  handlers?: PromiseHandlersInterface<RESULT>,
  deps: DependencyList = []
) {
  if (handlers === undefined) handlers = emptyObj;
  const [opened, setOpen] = useState(false);

  const innerCallback = useCallback(callback, [callback, opened, ...deps]);
  const promise = useRef<{
    promise: Promise<RESULT> | undefined;
    resolve: (data: RESULT | null) => void;
    reject: () => void;
  }>();

  const onClose = useCallback(() => {
    setOpen(false);
    promise.current?.reject();
    promise.current = undefined;
    handlers?.onReject && handlers.onReject();
  }, [handlers]);

  const onResolve = useCallback(
    data => {
      setOpen(false);
      promise.current!.resolve(data);
      promise.current = undefined;
      return handlers?.onResolve ? handlers.onResolve(data) : null;
    },
    [handlers]
  );

  const openProps = useRef<OPEN_PROPS>();
  const open = useCallback(
    (props?: OPEN_PROPS) => {
      openProps.current = props;
      setOpen(true);

      let resolveRef: any;
      let rejectRef: any;
      let promiseRef = new Promise<RESULT>((resolve, reject) => {
        resolveRef = resolve;
        rejectRef = reject;
      });
      promise.current = {
        promise: promiseRef,
        resolve: resolveRef,
        reject: rejectRef,
      };
      return promise.current.promise!.finally(() => {
        openProps.current = undefined;
      });
    },
    [openProps]
  );

  return [innerCallback(onResolve, onClose, opened, openProps.current!), open, onClose, opened] as const;
}
