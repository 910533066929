import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { t } from 'localization';

interface AppRedirectInterface {
  serviceData: ModelInfoData;
}

const AppRedirect = ({ serviceData }: AppRedirectInterface) => {
  if (!serviceData?.httpSettings.isHttpEnabled) return null;
  return (
    <>
      <Link
        to={`/app/${serviceData.modelAccountName || serviceData.id.accountId}/${
          serviceData.modelName || serviceData.id.modelId
        }`}
        style={{ display: 'contents', color: 'unset' }}
      >
        <Button
          type='button'
          color='primary'
          style={{ marginTop: '25px', width: 'max-content', maxWidth: '295px', alignSelf: 'center' }}
          data-test-id='Button:TestAppRedirect'
        >
          {t('TestingTab:TestAppLink')}
        </Button>
      </Link>
    </>
  );
};

export default React.memo(AppRedirect);
