import { UseFormReturn } from 'react-hook-form';

export type ServiceDevelopmentRequestFormMethodsType = UseFormReturn<ServiceDevelopmentRequestFormFieldsType, any>;

export enum ServiceDevelopmentRequestFormFieldsNames {
  name = 'name',
  contacts = 'contacts',
  comment = 'comment',
}

export const ServiceDevelopmentRequestFormFieldsNamesList = Object.values(ServiceDevelopmentRequestFormFieldsNames);

export type ServiceDevelopmentRequestFormFieldsType = {
  [ServiceDevelopmentRequestFormFieldsNames.name]: string;
  [ServiceDevelopmentRequestFormFieldsNames.contacts]: string;
  [ServiceDevelopmentRequestFormFieldsNames.comment]: string;
  commonError: any;
};
export const defaultFormValues: ServiceDevelopmentRequestFormFieldsType = {
  [ServiceDevelopmentRequestFormFieldsNames.name]: '',
  [ServiceDevelopmentRequestFormFieldsNames.contacts]: '',
  [ServiceDevelopmentRequestFormFieldsNames.comment]: '',
  commonError: undefined,
};
