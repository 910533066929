import React, { useCallback, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select, { SelectInterface } from 'components/Select';

import styles from './styles.module.scss';

interface ControlledSelectInterface extends SelectInterface {
  name: string;
  control: Control<any>;
  defaultValue?: string | number;
  rules?: any;
  onChange?: (value: (string | number)[] | null) => unknown;
  h3Title?: string;
}

const ControlledSelect = ({
  name,
  control,
  rules,
  defaultValue,
  options,
  onChange,
  h3Title,
  hidden,
  ...props
}: ControlledSelectInterface) => {
  const getValueFromOptions = useCallback(
    (inputValue?: string | number) => {
      const valueFromOptions = options?.find(option => option.value === inputValue)?.value;
      return valueFromOptions;
    },
    [options]
  );

  const [defaultValueBuffer, setDefaultValueBuffer] = useState<string | number | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => {
      setDefaultValueBuffer(getValueFromOptions(defaultValue));
    }, 0);
  }, [defaultValue, getValueFromOptions]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValueBuffer}
      render={({ field, fieldState: { invalid, error } }) => (
        <>
          {h3Title && <h3>{h3Title}</h3>}
          <Select
            {...props}
            value={getValueFromOptions(field.value)}
            onChange={value => {
              onChange && onChange(value);
              field.onChange(value?.[0]);
            }}
            fullWidth
            options={options}
            hidden={hidden}
          />
          {invalid && (
            <span className={styles.controlledSelect__error} hidden={hidden}>
              {error?.message || 'error'}
            </span>
          )}
        </>
      )}
    />
  );
};

export default React.memo(ControlledSelect);
