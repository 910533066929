import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';

import { STATE_PARAMS_TYPE } from '../contexts/CatalogContext';

import usePromiseProcessing from 'utils/hooks/usePromiseProcessing';
import { useDI } from 'contexts/AppContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useCancelableState } from 'utils/hooks/useCancelableState';
import { useLoading } from 'utils/hooks';

import { ModelApiService } from 'services/ApiServices/ModelApiService';
import { CatalogCategoryType } from '../types';
import {
  baseTaskTypesCategories,
  FAVORITE_DEBOUNCE_TIME,
  ITEMS_PER_PAGE,
  ITEMS_PER_PAGE_SHORT,
  ONLY_PUBLIC_MODELS,
} from '../constants';
import { AbortControllerService } from 'services/AbortControllerService';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { AppLogger } from 'services/AppLogger';

export const useCatalogPagination = ({
  updatePageQuery,
  pageQuery,
}: {
  updatePageQuery: (key: 'page', value: string) => void;
  pageQuery?: string;
}) => {
  const [pageCount, setPageCount] = useState<number | undefined>();

  const resetPage = useCallback(() => {
    updatePageQuery('page', '0');
  }, [updatePageQuery]);

  const page = (() => {
    if (pageQuery === undefined) {
      updatePageQuery('page', '0');
      return 0;
    }
    const parsedPage = parseInt(pageQuery);
    if (isNaN(parsedPage)) {
      updatePageQuery('page', '0');
      return 0;
    }
    return parsedPage;
  })();

  const handlePageChange = ({ selected: selectedPage }: { selected: number }) => {
    updatePageQuery('page', selectedPage.toString());
  };

  useEffect(() => {
    if (pageCount !== undefined && (pageCount === 0 || page > pageCount - 1)) {
      resetPage();
    }
  }, [page, pageCount, resetPage]);

  return {
    page,
    handlePageChange,
    pageCount,
    setPageCount,
  };
};

export const useCatalogCategories = () => {
  const modelApi = useDI(ModelApiService);

  const [taskTypeCategories, setTaskTypeCategories] = useState<CatalogCategoryType[]>(baseTaskTypesCategories);

  const [{ loading }, getCatalogCategories] = usePromiseProcessing(
    async () => {
      const {
        data: { categories: fetchedCategories },
      } = await modelApi.getCatalogCategories();
      setTaskTypeCategories(
        baseTaskTypesCategories.reduce((categories, category) => {
          const fetchedCategoryData = fetchedCategories.find(data => data.taskTypeName === category.name);
          if (!fetchedCategoryData) return categories;
          return [...categories, { ...category, modelsCount: fetchedCategoryData?.modelsCount }];
        }, [] as CatalogCategoryType[])
      );
    },
    [modelApi],
    {
      onError: error => {
        console.error(error);
        setTaskTypeCategories(baseTaskTypesCategories);
      },
    }
  );

  useEffect(() => {
    getCatalogCategories();
  }, [getCatalogCategories]);

  return { taskTypeCategories, loading, getCatalogCategories };
};

export const useCatalogState = (
  queryState: Partial<Record<STATE_PARAMS_TYPE, string>>,
  updateQueryState: (key: STATE_PARAMS_TYPE, value: string) => void,
  taskTypeCategories: CatalogCategoryType[]
) => {
  const taskTypeFilter = useMemo(
    () => taskTypeCategories.find(category => category.name === queryState?.taskType)?.name,
    [queryState?.taskType, taskTypeCategories]
  );

  const nameFilter = queryState?.name;

  const { page, handlePageChange, pageCount, setPageCount } = useCatalogPagination({
    updatePageQuery: updateQueryState,
    pageQuery: queryState?.page,
  });

  const [currentItems, setCurrentItems, setCurrentItemsCancelable, cancelCurrentItems] = useCancelableState<
    ModelInfoData[] | undefined
  >();

  const [isLoading, , startLoading, endLoading] = useLoading();

  const modelApi = useDI(ModelApiService);
  const abortController = useDI(AbortControllerService);

  const { user } = useAuthContext();

  const getModelsList = useCallback(async () => {
    startLoading();
    try {
      const { data = undefined, pageCount = 0 } = await abortController.get('modelsList', async signal => {
        if (!taskTypeFilter && !nameFilter) {
          const { data } = await modelApi.getFeaturedModels('', { signal });
          return { data, pageCount: 0 };
        } else {
          const itemsPerPage = nameFilter ? ITEMS_PER_PAGE_SHORT : ITEMS_PER_PAGE;
          const { data: modelsPageInfo } = await modelApi.getCatalogModels(
            user?.accountId.toString(),
            nameFilter,
            taskTypeFilter,
            page,
            ONLY_PUBLIC_MODELS,
            itemsPerPage,
            { signal }
          );
          return { data: modelsPageInfo?.records, pageCount: modelsPageInfo?.paging.totalPages || 0 };
        }
      });
      setCurrentItems(data);
      setPageCount(pageCount);
    } catch (error) {
      if (!axios.isCancel(error)) {
        AppLogger.error({ exception: error });
      }
    }
    endLoading();
  }, [
    abortController,
    endLoading,
    modelApi,
    nameFilter,
    page,
    setCurrentItems,
    setPageCount,
    startLoading,
    taskTypeFilter,
    user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFavouriteRequest = useCallback(
    debounce(async (serviceAccountId: number, serviceId: number, favorite: boolean) => {
      await abortController.get(serviceId.toString(), signal =>
        modelApi.setFavorite(serviceAccountId.toString(), serviceId.toString(), favorite, undefined, { signal })
      );
    }, FAVORITE_DEBOUNCE_TIME),
    []
  );

  const setFavorite = useCallback(
    async (serviceAccountId: number, serviceId: number, favorite: boolean) => {
      if (!user) return;
      const updatedCurrentItems = currentItems?.map(item =>
        item.id.modelId === serviceId ? { ...item, favorite } : item
      );

      setCurrentItemsCancelable(updatedCurrentItems);
      try {
        await debouncedSetFavouriteRequest(serviceAccountId, serviceId, favorite);
        setCurrentItems(updatedCurrentItems);
      } catch (error) {
        if (!axios.isCancel(error)) {
          cancelCurrentItems();
        }
      }
    },
    [cancelCurrentItems, currentItems, debouncedSetFavouriteRequest, setCurrentItems, setCurrentItemsCancelable, user]
  );

  useEffect(() => {
    getModelsList();
  }, [getModelsList]);

  return { currentItems, isLoading, setFavorite, page, handlePageChange, pageCount, taskTypeFilter, nameFilter };
};
