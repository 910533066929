import React from 'react';
import cn from 'classnames';
import { Spoiler } from '@just-ai/just-ui/dist/Spoiler';

import JSONView from 'components/JSONView';
import Divider from 'modules/Account/components/Divider';

import { prettifyJSON } from 'utils';
import { t } from 'localization';
import { PredictResultType } from './types';
import { Icon } from '@just-ai/just-ui';
import { useAppContext } from 'contexts/AppContext';

import './style.scss';
import styles from './styles.module.scss';

interface PredictResultInterface {
  result?: PredictResultType;
  resultRender?: (result: string) => React.ReactNode;
  hideJSONOutput?: boolean;
}

const PredictResult = ({ result, resultRender, hideJSONOutput = false }: PredictResultInterface) => {
  const { currencyType } = useAppContext();

  if (!result) return null;
  const { data, price, time, withError } = result;

  return (
    <div className={styles.predictResult__wrapper}>
      {result && (
        <>
          <Divider />
          <div className={styles.predictResult__responseHead}>
            <h3>{t('ServicePage:TestingForm:Result')}</h3>
            {time !== undefined && (
              <span className={styles.predictResult__price}>
                <Icon size='sm' name='farClock' />
                {t(`ServicePage:TestingForm:RequestTime`, { time: time?.toLocaleString() })}
              </span>
            )}
            {price !== undefined && (
              <span className={styles.predictResult__price}>
                <Icon size='sm' name='farCoins' />
                {t(`ServicePage:TestingForm:RequestPrice:${currencyType}`, { count: price })}
              </span>
            )}
          </div>
          {resultRender && resultRender(data)}
          {!hideJSONOutput && (
            <Spoiler
              initiallyOpened
              key='json-output'
              Header={() => <h6>{t('ServicePage:TestingForm:JSONOutput')}</h6>}
              withWrapper
            >
              <div
                className={styles.predictResult__content}
                data-test-id={!withError ? 'TestingForm:PredictResult' : 'TestingForm:PredictErrorMessage'}
              >
                <JSONView json={prettifyJSON(data)} className={cn('predict-result-json-view', withError && 'error')} />
              </div>
            </Spoiler>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(PredictResult);
