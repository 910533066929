import { Container, Token } from 'typedi';

export const tokens = {
  BANNER_MOUNT_ID: new Token<string>('BANNER_MOUNT_ID'),
  TELEGRAM_SUPPORT_LINK: new Token<string>('TELEGRAM_SUPPORT_LINK'),
  MANAGER_MAIL_LINK: new Token<string>('MANAGER_MAIL_LINK'),
};

Container.set([{ id: tokens.BANNER_MOUNT_ID, value: 'banner-root' }]);
Container.set([{ id: tokens.TELEGRAM_SUPPORT_LINK, value: 'https://t.me/caila_contact_bot' }]);
Container.set([{ id: tokens.MANAGER_MAIL_LINK, value: 'bd@tovie.ai' }]);
