import React, { useEffect, useRef, useState, useCallback, Ref, useImperativeHandle } from 'react';
import debounce from 'lodash/debounce';
import cn from 'classnames';

import Spinner from 'components/Spinner';

import './style.scss';

interface IframeInterface extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  src: string;
  fullHeight?: boolean;
  extraFreeHeight?: number;
  title?: string;
  onLoadControlled?: (iframeRef: HTMLIFrameElement | null) => void;
  messageEventHandler?: (event: MessageEvent<any>) => void;
  wrapClassName?: string;
  useSpinnerOnLoading?: boolean;
}

const Iframe = function (
  {
    src,
    fullHeight,
    extraFreeHeight = 0,
    title,
    onLoadControlled,
    messageEventHandler,
    wrapClassName,
    useSpinnerOnLoading = false,
    ...props
  }: IframeInterface,
  ref: Ref<HTMLIFrameElement | null>
) {
  const [height, setHeight] = useState<string>('0');
  const updateIframeHeightRef = useRef<ReturnType<typeof debounce>>();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [isLoading, setLoading] = useState(false);

  useImperativeHandle(ref, () => iframeRef.current, []);

  const handleOnload = useCallback(() => {
    messageEventHandler && window.addEventListener('message', messageEventHandler, false);
    if (updateIframeHeightRef?.current) {
      updateIframeHeightRef.current();
      updateIframeHeightRef.current.flush();
    }
    setLoading(false);
    onLoadControlled && onLoadControlled(iframeRef.current);
  }, [onLoadControlled, messageEventHandler]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    return () => {
      messageEventHandler && window.removeEventListener('message', messageEventHandler);
    };
  }, [messageEventHandler]);

  useEffect(() => {
    updateIframeHeightRef.current = debounce(() => {
      if (iframeRef.current?.contentDocument?.body && fullHeight) {
        setHeight(`${iframeRef.current.contentDocument.body.scrollHeight + extraFreeHeight}px`);
      }
    }, 500);

    if (fullHeight) {
      const resizeObserver = new ResizeObserver(() => {
        updateIframeHeightRef?.current && updateIframeHeightRef.current();
      });
      resizeObserver.observe(document.body);

      return () => {
        resizeObserver.unobserve(document.body);
      };
    }
  }, [extraFreeHeight, fullHeight]);

  return (
    <>
      <Spinner show={useSpinnerOnLoading ? isLoading : false} />
      <div className={cn('cmp-iframe', wrapClassName)}>
        <iframe
          {...props}
          ref={iframeRef}
          title={title}
          src={src}
          className='cmp-iframe__frame'
          frameBorder='0'
          width='100%'
          height={height}
          onLoad={handleOnload}
        />
      </div>
    </>
  );
};

export default React.memo(React.forwardRef(Iframe));
