import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import CardsList from '../CardsList';

import { ModelInfoData } from 'api/CailagateApi/api/client';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';
import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';

import { t } from 'localization';

import styles from './styles.module.scss';
import NoServices from '../NoServices';
import { useCatalogContext } from '../../contexts/CatalogContext';

interface CatalogSectionInterface {
  category: ServiceTaskTypes;
  services?: ModelInfoData[];
  isActive?: boolean;
}

const CatalogSection = ({ category, services, isActive }: CatalogSectionInterface) => {
  const { isLoading } = useCatalogContext();

  const { apps, basicServices } = useMemo(() => {
    const apps: ModelInfoData[] = [];
    const basicServices: ModelInfoData[] = [];

    services?.forEach(service => {
      if (isActive && service.httpSettings?.isHttpEnabled) {
        apps.push(service);
      } else {
        basicServices.push(service);
      }
    });

    return {
      apps,
      basicServices: apps.length ? basicServices : services,
    };
  }, [isActive, services]);

  const categoryDescription = t(`Catalog:Section:Description:${category}`);

  if (!isLoading && isActive && !basicServices?.length)
    return (
      <>
        <div className={styles.catalogSection__container}>
          <h2 className={cn(isActive && styles.catalogSection__title_active)}>
            {t(`Catalog:Section:Title:${category}`)}
          </h2>
          {!!categoryDescription && <p className={styles.catalogSection__description}>{categoryDescription}</p>}
          <NoServices />
        </div>
      </>
    );
  return (
    <div className={styles.catalogSection__container}>
      <h2 className={cn(isActive && styles.catalogSection__title_active)}>{t(`Catalog:Section:Title:${category}`)}</h2>

      {!!categoryDescription && <p className={styles.catalogSection__description}>{categoryDescription}</p>}
      {isActive && !!apps?.length && (
        <>
          <h2>{t('Catalog:Section:Title:Apps')}</h2>
          <CardsList items={apps} />
        </>
      )}
      {isActive && <h2>{t('Catalog:Section:Title:Services')}</h2>}
      <CardsList items={basicServices} />
      {!isActive && (
        <Link to={`/${CATALOG_BASE_PATH}?taskType=${category}`} className={cn(styles.catalogSection__link)}>
          {t(`Catalog:CategorySection:ViewAll`)}
        </Link>
      )}
    </div>
  );
};

export default React.memo(CatalogSection);
