import { UseFormReturn } from 'react-hook-form';

export type DemoRequestFormMethodsType = UseFormReturn<DemoRequestFormFieldsType, any>;

export enum DemoRequestFormFieldsNames {
  name = 'name',
  contacts = 'contacts',
}

export const DemoRequestFormFieldsNamesList = Object.values(DemoRequestFormFieldsNames);

export type DemoRequestFormFieldsType = {
  [DemoRequestFormFieldsNames.name]: string;
  [DemoRequestFormFieldsNames.contacts]: string;
  commonError: any;
};
export const defaultFormValues: DemoRequestFormFieldsType = {
  [DemoRequestFormFieldsNames.name]: '',
  [DemoRequestFormFieldsNames.contacts]: '',
  commonError: undefined,
};
