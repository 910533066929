import { useState, useCallback } from 'react';

interface PromiseStatusInterface<PAYLOAD> {
  loading: boolean;
  error: string | null;
  result: PAYLOAD | null;
}

export interface ResultInterface<PAYLOAD> {
  loading: boolean;
  error: string | null;
  result: PAYLOAD | null;
  reset: () => void;
}

const initialState = {
  result: null,
  loading: false,
  error: null,
};

export default function usePromiseProcessing<PAYLOAD, ARGS extends any[]>(
  promiseFunc: (...args: ARGS) => Promise<PAYLOAD>,
  deps: any[] = [],
  config: {
    throwOnError?: boolean;
    onSuccess?: (result: PAYLOAD) => void;
    onError?: (error: Error) => void;
    onFinish?: () => void;
  } = {}
): [ResultInterface<PAYLOAD>, (...args: ARGS) => Promise<PAYLOAD>, (...args: ARGS) => Promise<PAYLOAD>] {
  const [{ result, error, loading }, setPromiseInfo] = useState<PromiseStatusInterface<PAYLOAD>>(initialState);

  const run = useCallback(
    (isOptimistic: boolean) =>
      (...args: ARGS) => {
        setPromiseInfo(prev => ({
          ...prev,
          loading: !isOptimistic,
        }));

        return promiseFunc(...args)
          .then(data => {
            setPromiseInfo({
              result: data,
              loading: false,
              error: null,
            });
            config.onSuccess && config.onSuccess(data);
            return data;
          })
          .catch(error => {
            setPromiseInfo({
              result: null,
              loading: false,
              error: error ? error.errorMessage || error.message : 'Unknown error',
            });
            config.onError && config.onError(error);

            if (config.throwOnError) throw error;
            return error;
          })
          .then(data => {
            config.onFinish && config.onFinish();
            return data;
          });
      },
    // eslint-disable-next-line
    [...deps]
  );

  const func = useCallback(run(false), [run]);
  const optimistic = useCallback(run(true), [run]);
  const reset = useCallback(() => setPromiseInfo(initialState), []);

  return [
    {
      result,
      loading,
      error,
      reset,
    },
    func,
    optimistic,
  ];
}
