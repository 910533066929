import React from 'react';
import { Modal } from '@just-ai/just-ui/dist/Modal';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';

import { t } from 'localization';
import styles from './styles.module.scss';

interface MessageSentNotificationModalInterface {
  isOpen: boolean;
  onClose: () => void;
}

const MessageSentNotificationModal = ({ isOpen, onClose }: MessageSentNotificationModalInterface) => {
  return (
    <Modal
      title={t('MessageSentNotificationModal:Title')}
      isOpen={isOpen}
      className={styles.messageSentNotificationModal}
      disableActionButtonAutoFocus
      size='md'
      buttonSubmitText={t('MessageSentNotificationModal:Submit')}
      onClickCloseIcon={onClose}
      onActionClick={onClose}
    >
      <p>{t('MessageSentNotificationModal:MessageFirst')}</p>
      <p>{t('MessageSentNotificationModal:MessageSecond')}</p>
    </Modal>
  );
};

export default React.memo(MessageSentNotificationModal);

export const useMessageSentNotificationModal = () => {
  return usePromisifyComponent((onResolve, onClose, opened) => (
    <MessageSentNotificationModal isOpen={opened} onClose={onClose} />
  ));
};
