import React, { useMemo } from 'react';

import { ModelInfoData } from 'api/CailagateApi/api/client';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';
import { useCatalogContext } from '../../contexts/CatalogContext';

import CatalogSection from '../CatalogSection';

import styles from './styles.module.scss';

interface CatalogSectionInterface {
  services?: ModelInfoData[];
}

const CatalogListByCategory = ({ services }: CatalogSectionInterface) => {
  const { taskTypeCategories } = useCatalogContext();

  const categoryEntries = useMemo(() => {
    const items = {} as Record<ServiceTaskTypes, ModelInfoData[]>;

    services?.forEach(service => {
      if (service.taskType) {
        const taskType = service.taskType as ServiceTaskTypes;
        if (!items[taskType]) {
          items[taskType] = [];
        }
        items[taskType].push(service);
      }
    });

    return taskTypeCategories
      .map(taskTypeCategory => ({
        category: taskTypeCategory.name,
        value: items[taskTypeCategory.name] || [],
      }))
      .filter(entry => entry.value.length > 0);
  }, [services, taskTypeCategories]);

  if (!categoryEntries) return null;

  return (
    <div className={styles.catalogListByCategory}>
      {categoryEntries.map(({ category, value }, index) => {
        return !value?.length ? null : (
          <CatalogSection key={`${category}${index}`} category={category as ServiceTaskTypes} services={value} />
        );
      })}
    </div>
  );
};

export default React.memo(CatalogListByCategory);
