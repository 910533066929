import React, { useRef, useState } from 'react';
import { JustSelect, JustSelectProps, OptionType } from '@just-ai/just-ui';
import cn from 'classnames';

import useLockScroll from 'utils/hooks/useLockScroll';
import styles from './styles.module.scss';

export interface SelectInterface extends JustSelectProps {
  hidden?: boolean;
  disableLockScroll?: boolean;
}
export interface SelectOptionType extends OptionType {}

const Select = (
  { className, hidden = false, disableLockScroll = false, ...props }: SelectInterface,
  externalRef: React.Ref<JustSelect>
) => {
  const [isOpened, setIsOpened] = useState(false);
  const childRef = useRef<HTMLDivElement>();
  useLockScroll(childRef.current, !disableLockScroll && isOpened);

  return (
    <JustSelect
      className={cn(styles.select, className, hidden && styles.select__hidden)}
      getRef={ref => {
        childRef.current = ref;
      }}
      onOpen={setIsOpened}
      ref={externalRef}
      {...props}
    />
  );
};

export default React.memo(React.forwardRef(Select));
