import { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';

import { BalanceRefillFormFieldsType, BalanceRefillFormMethodsType, defaultFormValues } from './types';

import { t } from 'localization';
import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';

import { useAuthContext } from 'contexts/AuthContext';
import { FEATURES } from 'contexts/AuthContext/constants';

import styles from './styles.module.scss';

interface BalanceRefillFormInterface {
  formMethods: BalanceRefillFormMethodsType;
}

export const useBalanceRefillForm = (formDataDeps: any[] = []): UseFormReturn<BalanceRefillFormFieldsType, any> => {
  return useForm<BalanceRefillFormFieldsType & { commonError: any }>({
    criteriaMode: 'all',
    defaultValues: { ...defaultFormValues },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
};

export default function BalanceRefillForm({ formMethods }: BalanceRefillFormInterface) {
  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;

  const { userHasFeature } = useAuthContext();
  const showFromIncomeBalanceOption = userHasFeature(FEATURES.MLP_PUBLIC_MODEL_ADMIN);

  const methodsList = useMemo(
    () => [
      {
        label: t('BalanceRefillModal:fromCard'),
        name: 'fromCard',
        value: 'fromCard',
      },
      {
        label: t('BalanceRefillModal:fromIncomeBalance'),
        name: 'fromIncomeBalance',
        value: 'fromIncomeBalance',
      },
    ],
    []
  );

  const watchMethod = watch('method');

  useEffect(() => {
    if (!showFromIncomeBalanceOption) {
      formMethods.setValue('method', 'fromCard');
    }
  }, [formMethods, showFromIncomeBalanceOption]);

  return (
    <FormContainer
      className={styles.balanceRefillForm__container}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      {showFromIncomeBalanceOption ? (
        <>
          <h3>{t('BalanceRefillModal:BalanceRefillOptions')}</h3>
          <Controller
            name='method'
            control={control}
            render={({ field: { onChange, value } }) => (
              <SimpleRadioButtonsGroup
                name='method'
                value={value || ''}
                onChange={onChange}
                list={methodsList}
                containerClassName={styles.balanceRefillForm__radioButtons}
              />
            )}
          />
        </>
      ) : (
        <h3 style={{ padding: '0' }}>{t('BalanceRefillModal:PayByCard')}</h3>
      )}
      {watchMethod === 'fromIncomeBalance' && showFromIncomeBalanceOption && (
        <ControlledInput
          data-test-id='BalanceRefillForm:fromIncomeBalance'
          name='sum'
          placeholder={t('BalanceRefillModal:Sum')}
          control={control}
          type='number'
          step='any'
        />
      )}
      {(watchMethod === 'fromCard' || !showFromIncomeBalanceOption) && (
        <ControlledInput
          data-test-id='BalanceRefillForm:method'
          name='amountCurrency'
          placeholder={t('BalanceRefillModal:Sum')}
          control={control}
          type='number'
          step='any'
          rules={{
            min: {
              value: 1,
              message: t('BalanceRefillModal:AmountCurrencyMessage'),
            },
          }}
        />
      )}
    </FormContainer>
  );
}
