import React, { useCallback, useEffect } from 'react';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import Spinner from 'components/Spinner';

import ContactRequestForm, { useContactRequestForm } from './components/ContactRequestForm';

import {
  ContactRequestFormFieldsType,
  ContactRequestFormFieldsNamesList,
  defaultFormValues,
  ContactRequestFormFieldsNames,
} from './components/ContactRequestForm/types';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';
import { useLoading } from 'utils/hooks';
import { handleFormErrors } from 'utils/form';
import { t } from 'localization';
import { useDI } from 'contexts/AppContext';
import { ManualManagementApiService } from 'services/ApiServices/ManualManagementApiService';

import styles from './styles.module.scss';

interface ContactRequestModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
}

const ContactRequestModal = ({ isOpen, onClose, onResolve }: ContactRequestModalInterface) => {
  const formMethods = useContactRequestForm();
  const [isLoading, , startLoading, endLoading] = useLoading();

  const manualManagementApi = useDI(ManualManagementApiService);

  const submit = useCallback(
    async (formValues: ContactRequestFormFieldsType) => {
      startLoading();
      try {
        await manualManagementApi.sendEmail({
          contacts: JSON.stringify({
            name: formValues[ContactRequestFormFieldsNames.name],
            contacts: formValues[ContactRequestFormFieldsNames.contacts],
          }),
          comment: formValues[ContactRequestFormFieldsNames.question],
        });
        onResolve();
      } catch (error) {
        handleFormErrors(error, ContactRequestFormFieldsNamesList, formMethods.setError);
      } finally {
        endLoading();
      }
    },
    [endLoading, formMethods.setError, manualManagementApi, onResolve, startLoading]
  );

  useEffect(() => {
    if (!isOpen) {
      formMethods.reset(defaultFormValues);
    }
  }, [formMethods, isOpen]);

  return (
    <Modal
      title={t('ContactRequestModal:Title')}
      isOpen={isOpen}
      disableActionButtonAutoFocus
      className={styles.contactRequestModal}
      size='md'
      buttonSubmitText={t('ContactRequestModal:Submit')}
      buttonCancelText={t('ContactRequestModal:Cancel')}
      onClickCloseIcon={onClose}
      onActionClick={formMethods.handleSubmit(submit)}
      onCancelClick={onClose}
    >
      <Spinner centered show={isLoading} />
      <ContactRequestForm formMethods={formMethods} />
    </Modal>
  );
};

export default React.memo(ContactRequestModal);

export const useContactRequestModal = (props?: { onResolve?: (data: unknown) => void }) => {
  return usePromisifyComponent(
    (onResolve, onClose, opened) => <ContactRequestModal isOpen={opened} onClose={onClose} onResolve={onResolve} />,
    {
      onResolve: props?.onResolve,
    }
  );
};
