import React, { useCallback, useEffect } from 'react';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import Spinner from 'components/Spinner';

import CloneForm, { useCloneForm } from './components/CloneForm';

import { CloneFormFieldsType, CloneFormFieldsNamesList, defaultFormValues } from './components/CloneForm/types';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';
import { useLoading } from 'utils/hooks';
import { notifyActionMessage } from 'components/MessageContainer';
import { handleFormErrors } from 'utils/form';
import { t } from 'localization';

export interface CloneInputInterface<CLONE_DATA extends unknown = unknown> {
  cloneData?: CLONE_DATA;
  name: string;
}

interface CloneModalInterface<CLONE_DATA extends unknown> {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
  onClone: ({ cloneData, name }: CloneInputInterface<CLONE_DATA>) => Promise<void>;
  data?: CLONE_DATA;
  name?: string;
}

const CloneModal = <CLONE_DATA extends unknown>({
  isOpen,
  onClose,
  onResolve,
  onClone,
  data,
  name,
}: CloneModalInterface<CLONE_DATA>) => {
  const formMethods = useCloneForm(name);

  useEffect(() => {
    if (isOpen && name !== undefined) {
      formMethods.reset({ ...defaultFormValues, name });
    }
  }, [formMethods, isOpen, name]);

  const [isLoading, , startLoading, endLoading] = useLoading();

  const submit = useCallback(
    async ({ name }: CloneFormFieldsType) => {
      startLoading();
      try {
        await onClone({ cloneData: data, name });
        onResolve();
      } catch (error) {
        handleFormErrors(error, CloneFormFieldsNamesList, formMethods.setError);
      }
      endLoading();
    },
    [data, endLoading, formMethods.setError, onClone, onResolve, startLoading]
  );

  return (
    <Modal
      title={t('CloneModal:Title:Clone')}
      isOpen={isOpen}
      disableActionButtonAutoFocus
      size='md'
      buttonSubmitText={t('CloneModal:Button:Clone')}
      buttonCancelText={t('CloneModal:Cancel')}
      onClickCloseIcon={onClose}
      onActionClick={formMethods.handleSubmit(submit)}
      onCancelClick={onClose}
    >
      <Spinner centered show={isLoading} />
      <CloneForm formMethods={formMethods} />
    </Modal>
  );
};

export default React.memo(CloneModal);

export const useCloneModal = <CLONE_DATA extends unknown>(
  onClone: ({ cloneData, name }: CloneInputInterface<CLONE_DATA>) => Promise<void>
) => {
  return usePromisifyComponent<unknown, CloneInputInterface<CLONE_DATA>>(
    (onResolve, onClose, opened, data) => (
      <CloneModal
        isOpen={opened}
        onClose={onClose}
        onResolve={onResolve}
        onClone={onClone}
        data={data?.cloneData}
        name={data?.name}
      />
    ),
    {
      onResolve: () => notifyActionMessage(t('CloneModal:CloneSuccessMessage'), { theme: 'dark', type: 'success' }),
    }
  );
};
