import React, { useCallback } from 'react';
import { AudioPlayer } from '@just-ai/just-ui';

import IconButton from 'components/IconButton';
import PredictResult from '../../../PredictResult';

import { downloadFileFromUrl } from 'utils';

import { useTestWidgetContext } from 'modules/ServiceTestComponents/contexts/TestWidgetContext';
import { PredictResultType } from 'modules/ServiceTestComponents/PredictResult/types';

import styles from './styles.module.scss';

interface TesterResponseInterface {
  result?: PredictResultType;
  isTTS?: boolean;
}

const TesterResponse = ({ result, isTTS = false }: TesterResponseInterface) => {
  const { serviceData } = useTestWidgetContext();
  const hasAudioResult = isTTS && result && result.data && !result.withError;

  const handleDownloadAudio = useCallback(() => {
    if (hasAudioResult && result.data) {
      downloadFileFromUrl(result.data, `${serviceData.modelName}.wav`);
    }
  }, [hasAudioResult, result?.data, serviceData.modelName]);

  if (result && hasAudioResult) {
    return (
      <>
        <PredictResult
          result={result}
          resultRender={data => {
            return (
              <div className={styles.testerResponse__audio} data-test-id='Tester:PredictResultAudio'>
                <AudioPlayer
                  url={data}
                  autoPlay
                  dataTestId={{
                    menuButton: 'Tester:PredictResultAudio:Menu',
                    playButton: 'Tester:PredictResultAudio:Play',
                    volumeButton: 'Tester:PredictResultAudio:Volume',
                  }}
                />
                <IconButton
                  className={styles.testerResponse__downloadButton}
                  name='farArrowToBottom'
                  onClick={handleDownloadAudio}
                />
              </div>
            );
          }}
          hideJSONOutput
        />
      </>
    );
  }
  return <PredictResult result={result} />;
};

export default React.memo(TesterResponse);
