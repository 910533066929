import React, { useCallback } from 'react';

import IconButton from 'components/IconButton';
import StatusIndicator from 'components/StatusIndicator';
import { StatusType } from 'components/StatusIndicator/types';
import TagsChip, { TagsChipType } from 'components/TagsChip';

import { t } from 'localization';

import styles from './styles.module.scss';

type CardItemHeadProps = {
  serviceId: string;
  isFavorite: boolean;
  toggleFavorite: () => Promise<void>;
  showFavoriteToggle?: boolean;
  status?: StatusType;
  fittable: boolean;
  isHttpEnabled: boolean;
  prototype?: boolean;
};

const CardItemHead = ({
  serviceId,
  isFavorite,
  toggleFavorite,
  status,
  fittable,
  isHttpEnabled,
  showFavoriteToggle = false,
  prototype: isPrototype = false,
}: CardItemHeadProps) => {
  const handleToggle = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      await toggleFavorite();
    },
    [toggleFavorite]
  );

  return (
    <div className={styles.head}>
      <div className={styles.head__name}>
        {isHttpEnabled && <TagsChip key='fitRequired' text={t('Catalog:Card:Tag:App')} type={TagsChipType.App} />}
        <span className='name'>{serviceId}</span>
        {!isPrototype && <StatusIndicator className={styles.head__indicator} status={status} />}
      </div>
      <div className={styles.head__infoAndActions}>
        <div className={styles.head__infoTags}>
          {fittable && (
            <TagsChip
              key='fitRequired'
              style={{ backgroundColor: '#D6E2F2', color: '#D6E2F2' }}
              className={styles.head__tag}
              text={t('Catalog:Card:FitRequired')}
              type={TagsChipType.TextTask}
              iconName='farGraduationCap'
              iconSize='sm'
            />
          )}
          {isPrototype && (
            <TagsChip
              key='isPrototype'
              style={{ backgroundColor: '#FEDF78', color: '#FEDF78' }}
              className={styles.head__tag}
              text={t('Catalog:Card:DeployRequired')}
              type={TagsChipType.TextTask}
              iconName='farServer'
              iconSize='sm'
            />
          )}
        </div>
        {showFavoriteToggle && (
          <IconButton
            className={styles.head__favoriteIconButton}
            data-test-id='TokensPage:Token:Delete'
            name={isFavorite ? 'faStar' : 'farStar'}
            type='button'
            size='sm'
            onClick={handleToggle}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(CardItemHead);
