import React, { useCallback, useEffect } from 'react';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import Spinner from 'components/Spinner';

import ServiceDevelopmentRequestForm, {
  useServiceDevelopmentRequestForm,
} from './components/ServiceDevelopmentRequestForm';

import {
  ServiceDevelopmentRequestFormFieldsType,
  ServiceDevelopmentRequestFormFieldsNamesList,
  defaultFormValues,
  ServiceDevelopmentRequestFormFieldsNames,
} from './components/ServiceDevelopmentRequestForm/types';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';
import { useLoading } from 'utils/hooks';
import { handleFormErrors } from 'utils/form';
import { t } from 'localization';
import { useDI } from 'contexts/AppContext';
import { ManualManagementApiService } from 'services/ApiServices/ManualManagementApiService';

import styles from './styles.module.scss';

interface ServiceDevelopmentRequestModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
}

const ServiceDevelopmentRequestModal = ({ isOpen, onClose, onResolve }: ServiceDevelopmentRequestModalInterface) => {
  const formMethods = useServiceDevelopmentRequestForm();
  const [isLoading, , startLoading, endLoading] = useLoading();

  const manualManagementApi = useDI(ManualManagementApiService);

  const submit = useCallback(
    async (formValues: ServiceDevelopmentRequestFormFieldsType) => {
      startLoading();
      try {
        await manualManagementApi.sendEmail({
          contacts: JSON.stringify({
            name: formValues[ServiceDevelopmentRequestFormFieldsNames.name],
            contacts: formValues[ServiceDevelopmentRequestFormFieldsNames.contacts],
          }),
          comment: formValues[ServiceDevelopmentRequestFormFieldsNames.comment],
        });
        onResolve();
      } catch (error) {
        handleFormErrors(error, ServiceDevelopmentRequestFormFieldsNamesList, formMethods.setError);
      } finally {
        endLoading();
      }
    },
    [endLoading, formMethods.setError, manualManagementApi, onResolve, startLoading]
  );

  useEffect(() => {
    if (!isOpen) {
      formMethods.reset(defaultFormValues);
    }
  }, [formMethods, isOpen]);

  return (
    <Modal
      title={t('ServiceDevelopmentRequestModal:Title')}
      isOpen={isOpen}
      className={styles.serviceDevelopmentRequestModal}
      disableActionButtonAutoFocus
      size='md'
      buttonSubmitText={t('ServiceDevelopmentRequestModal:Submit')}
      buttonCancelText={t('ServiceDevelopmentRequestModal:Cancel')}
      onClickCloseIcon={onClose}
      onActionClick={formMethods.handleSubmit(submit)}
      onCancelClick={onClose}
    >
      <Spinner centered show={isLoading} />
      <ServiceDevelopmentRequestForm formMethods={formMethods} />
    </Modal>
  );
};

export default React.memo(ServiceDevelopmentRequestModal);

export const useServiceDevelopmentRequestModal = (props?: { onResolve?: (data: unknown) => void }) => {
  return usePromisifyComponent(
    (onResolve, onClose, opened, inputData) => (
      <ServiceDevelopmentRequestModal isOpen={opened} onClose={onClose} onResolve={onResolve} />
    ),
    {
      onResolve: props?.onResolve,
    }
  );
};
