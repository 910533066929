import ResizableTextArea, { ResizableTextAreaInterface } from 'components/ResizableTextArea';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface ControlledResizableTextAreaInterface<FIELD_NAME extends string>
  extends Omit<
    ResizableTextAreaInterface,
    'name' | 'value' | 'onChange' | 'onBlur' | 'errorText' | 'hasError' | 'onFocus'
  > {
  placeholder?: string;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  control: Control<any>;
  name: FIELD_NAME;
  h3Title?: string;
}

const ControlledResizableTextArea = <FIELD_NAME extends string>({
  placeholder,
  className,
  onFocus,
  onBlur,
  control,
  name,
  h3Title,
  ...props
}: ControlledResizableTextAreaInterface<FIELD_NAME>) => {
  return (
    <>
      {h3Title && <h3>{h3Title}</h3>}
      <Controller
        name={name}
        control={control}
        rules={{ required: false }}
        render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
          <ResizableTextArea
            {...props}
            value={value}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            className={className}
            onFocus={onFocus}
            onBlur={onBlur}
            errorText={invalid ? error?.message : ''}
            hasError={invalid}
          />
        )}
      />
    </>
  );
};

export default React.memo(ControlledResizableTextArea);
