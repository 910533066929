import React, { useCallback, useEffect } from 'react';
import { Modal } from '@just-ai/just-ui/dist/Modal';
import Spinner from 'components/Spinner';

import DemoRequestForm, { useDemoRequestForm } from './components/DemoRequestForm';

import {
  DemoRequestFormFieldsType,
  DemoRequestFormFieldsNamesList,
  defaultFormValues,
  DemoRequestFormFieldsNames,
} from './components/DemoRequestForm/types';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';
import { useLoading } from 'utils/hooks';
import { handleFormErrors } from 'utils/form';
import { t } from 'localization';
import { useDI } from 'contexts/AppContext';
import { ManualManagementApiService } from 'services/ApiServices/ManualManagementApiService';

import styles from './styles.module.scss';

interface DemoRequestModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
}

const DemoRequestModal = ({ isOpen, onClose, onResolve }: DemoRequestModalInterface) => {
  const formMethods = useDemoRequestForm();
  const [isLoading, , startLoading, endLoading] = useLoading();
  const manualManagementApi = useDI(ManualManagementApiService);

  const submit = useCallback(
    async (formValues: DemoRequestFormFieldsType) => {
      startLoading();
      try {
        await manualManagementApi.sendEmail({
          contacts: JSON.stringify({
            name: formValues[DemoRequestFormFieldsNames.name],
            contacts: formValues[DemoRequestFormFieldsNames.contacts],
          }),
        });
        onResolve();
      } catch (error) {
        handleFormErrors(error, DemoRequestFormFieldsNamesList, formMethods.setError);
      } finally {
        endLoading();
      }
    },
    [endLoading, formMethods.setError, manualManagementApi, onResolve, startLoading]
  );

  useEffect(() => {
    if (!isOpen) {
      formMethods.reset(defaultFormValues);
    }
  }, [formMethods, isOpen]);

  return (
    <Modal
      title={t('DemoRequestModal:Title')}
      isOpen={isOpen}
      className={styles.demoRequestModal}
      disableActionButtonAutoFocus
      size='md'
      buttonSubmitText={t('DemoRequestModal:Submit')}
      buttonCancelText={t('DemoRequestModal:Cancel')}
      onClickCloseIcon={onClose}
      onActionClick={formMethods.handleSubmit(submit)}
      onCancelClick={onClose}
    >
      <Spinner centered show={isLoading} />
      <DemoRequestForm formMethods={formMethods} />
    </Modal>
  );
};

export default React.memo(DemoRequestModal);

export const useDemoRequestModal = (props?: { onResolve?: (data: unknown) => void }) => {
  return usePromisifyComponent(
    (onResolve, onClose, opened, inputData) => (
      <DemoRequestModal isOpen={opened} onClose={onClose} onResolve={onResolve} />
    ),
    {
      onResolve: props?.onResolve,
    }
  );
};
