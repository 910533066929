import React from 'react';
import { Helmet } from 'react-helmet';

import CatalogSidebar from './components/CatalogSidebar';

import { useAppContext } from 'contexts/AppContext';

import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';

import styles from './styles.module.scss';
import CatalogContent from './components/CatalogContent';
import { CatalogContextProviderComponent } from './contexts/CatalogContext';

const CatalogListPage = () => {
  const { canonicalHref } = useAppContext();

  return (
    <>
      <div className={styles.catalogListPage__background} />
      <div className={styles.catalogListPage}>
        <Helmet>{canonicalHref && <link rel='canonical' href={`${canonicalHref}/${CATALOG_BASE_PATH}`} />}</Helmet>
        <CatalogContextProviderComponent>
          <div className={styles.catalogListPage__container}>
            <CatalogSidebar />
            <CatalogContent />
          </div>
        </CatalogContextProviderComponent>
      </div>
    </>
  );
};

export default React.memo(CatalogListPage);
