import React, { useMemo } from 'react';

import Menu from 'components/Menu';
import { useAuthContext } from 'contexts/AuthContext';

import styles from './styles.module.scss';
import { t } from 'localization';
import { WORKSPACE_BASE_PATH } from 'routes';
import { useAppContext } from 'contexts/AppContext';
import { PERMISSIONS } from 'contexts/AuthContext/constants';
import { documentationLink } from 'App';

const MobileScreenMenu = () => {
  const { loginWithAccountPageRedirect, logout, isAuthenticated, userHasPermission, isUserSuperadmin } =
    useAuthContext();
  const { isBillingEnabled } = useAppContext();

  const menuList = useMemo(
    () => [
      {
        label: 'Вход',
        onClick: () => loginWithAccountPageRedirect(),
      },
      {
        label: t('Header:MobileScreenMenu:Catalog'),
        linkTo: '/catalog',
      },

      {
        label: t('Header:MobileScreenMenu:Documentation'),
        link: documentationLink,
      },
    ],
    [loginWithAccountPageRedirect]
  );

  const menuListAuthorized = useMemo(
    () => [
      {
        label: t('Header:AccountMenu:Account'),
        linkTo: `/${WORKSPACE_BASE_PATH}/account-info`,
        hide: !(userHasPermission(PERMISSIONS.MLP_ADMIN) && !isUserSuperadmin),
      },
      {
        label: t('Header:MobileScreenMenu:Catalog'),
        linkTo: '/catalog',
      },
      {
        label: t('Header:MobileScreenMenu:MySpace'),
        linkTo: '/workspace',
      },
      //INFO: hidden according to CAILA-2892
      // {
      //   label: t('Header:AccountMenu:Balance'),
      //   hide: !(isBillingEnabled && !isUserSuperadmin),
      //   // data-test-id='headerMenuBalanceLink',
      //   linkTo: `/${WORKSPACE_BASE_PATH}/balance-info`,
      // },
      {
        label: t('Header:AccountMenu:Logout'),
        onClick: () => logout(),
      },
    ],
    [isUserSuperadmin, logout, userHasPermission]
  );

  return (
    <Menu
      toggleClassName={styles.mobileScreenMenu__toggle}
      toggleDataTestId='Header:Menu:Open'
      list={isAuthenticated ? menuListAuthorized : menuList}
      toggleIconName='farBars'
    />
  );
};

export default React.memo(MobileScreenMenu);
