import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import cn from 'classnames';

import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';
import ControlledInput from 'components/ControlledInput';
import ControlledFileUpload from 'components/ControlledFileUpload';
import ControlledSelect from 'components/ControlledSelect';

import { DatasetSelectMode } from '../../types';
import { useTrainingFormContext } from '../..';
import { useTrainingContext } from 'modules/ServiceFitComponents/contexts/TrainingContext';

import { documentationLink } from 'App';
import { t } from 'localization';

import styles from './styles.module.scss';

const DatasetSelect = () => {
  const { control, watch, register } = useTrainingFormContext();
  const { datasets, datasetDataTypes } = useTrainingContext();
  const datasetList = useMemo(
    () => datasets?.map(dataset => ({ label: dataset.name, value: dataset.id?.datasetId?.toString() as string })),
    [datasets]
  );

  const datasetDataTypesList = useMemo(
    () =>
      datasetDataTypes?.map(datasetDataType => ({
        label: datasetDataType,
        value: datasetDataType,
      })),
    [datasetDataTypes]
  );

  const watchDatasetSelectMode = watch('datasetSelectMode');

  const DatasetSelectModeOptions = useMemo(
    () => [
      {
        label: t(`TrainingWidget:TrainingForm:${DatasetSelectMode.fromList}`),
        name: DatasetSelectMode.fromList,
        value: DatasetSelectMode.fromList,
      },
      {
        label: t(`TrainingWidget:TrainingForm:${DatasetSelectMode.upload}`),
        name: DatasetSelectMode.upload,
        value: DatasetSelectMode.upload,
      },
    ],
    []
  );
  //TODO: possible update link
  const datasetExamplesLink = documentationLink + '/docs/ru/reference/data-types/';

  return (
    <>
      <input type='hidden' {...register('datasetSelectMode')} />
      <>
        <h3>{t('TrainingWidget:TrainingForm:TrainingDatasets')}</h3>
        <Controller
          name='datasetSelectMode'
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <SimpleRadioButtonsGroup
              value={value}
              onChange={onChange}
              containerClassName={styles.datasetSelect__radioButtons}
              name='TrainingFormDatasetSelect'
              list={DatasetSelectModeOptions}
            />
          )}
        />
        <ControlledSelect
          data-test-id='datasetDataType'
          name='datasetDataType'
          control={control}
          rules={{ required: false }}
          options={datasetDataTypesList}
          fullWidth
          className={cn(
            styles.datasetSelect__select,
            watchDatasetSelectMode !== 'upload' && styles.datasetSelect__hidden
          )}
          inputPlaceholder={t('TrainingWidget:TrainingForm:DatasetDataType')}
        />
        {watchDatasetSelectMode === 'upload' && (
          <>
            <ControlledInput
              data-test-id='datasetName'
              name='datasetName'
              className={styles.datasetSelect__input}
              placeholder={t('TrainingWidget:TrainingForm:DatasetNamePlaceholder')}
              control={control}
            />
            <ControlledFileUpload
              name='datasetContent'
              data-test-id='TrainingWidget:TrainingForm:FileUploader'
              control={control}
              subText={
                <span className={styles.datasetSelect__uploadRequirements}>
                  {t('TrainingWidget:TrainingForm:DatasetFileRequirements')}{' '}
                  <a
                    onClick={e => {
                      e?.stopPropagation();
                    }}
                    target='_parent'
                    href={datasetExamplesLink}
                  >
                    {t('TrainingWidget:TrainingForm:DatasetFileRequirementsHere')}
                  </a>
                </span>
              }
            />
          </>
        )}
        <ControlledSelect
          name='datasetId'
          control={control}
          rules={{ required: false }}
          options={datasetList}
          fullWidth
          className={cn(
            styles.datasetSelect__select,
            watchDatasetSelectMode === 'upload' && styles.datasetSelect__hidden
          )}
          inputPlaceholder={t('TrainingWidget:TrainingForm:SelectDataset')}
        />
      </>
    </>
  );
};

export default React.memo(DatasetSelect);
