import React from 'react';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import { messageTypes } from './types';

import styles from './styles.module.scss';

interface ActionMessagesContainerInterface {}

export const notifyActionMessage = (message: string, options?: ToastOptions<{}>) => {
  toast(message, { ...options, containerId: messageTypes.actionMessage });
};

export const notifyCommonMessage = (message: string, options?: ToastOptions<{}>) => {
  toast(message, { ...options, containerId: messageTypes.commonMessage });
};
export const notifyGeneralInfoMessage = (message: string, options?: ToastOptions<{}>) => {
  toast(message, { ...options, containerId: messageTypes.generalInfoMessage });
};

const MessageContainer = (props: ActionMessagesContainerInterface) => {
  return (
    <>
      <ToastContainer
        enableMultiContainer
        autoClose={5000}
        position={toast.POSITION.TOP_RIGHT}
        closeButton={false}
        className={styles.actionMessageContainer}
        containerId={messageTypes.actionMessage}
        theme='colored'
        style={{ whiteSpace: 'pre' }}
        limit={7}
        hideProgressBar
        newestOnTop
      />
      <ToastContainer
        enableMultiContainer
        autoClose={5000}
        position={toast.POSITION.TOP_CENTER}
        closeButton={false}
        containerId={messageTypes.generalInfoMessage}
        theme='colored'
        style={{ whiteSpace: 'pre' }}
        className={styles.generalInfoMessageContainer}
        limit={3}
        hideProgressBar
        newestOnTop
      />
      <ToastContainer
        enableMultiContainer
        position={toast.POSITION.BOTTOM_CENTER}
        closeButton={false}
        autoClose={1500}
        hideProgressBar
        containerId={messageTypes.commonMessage}
        className={styles.commonMessageContainer}
      />
    </>
  );
};

export default React.memo(MessageContainer);
