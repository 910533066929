import React from 'react';

import { t } from 'localization';

import Spinner from 'components/Spinner';
import SearchFilter from 'components/SearchFilter';
import Paginate from 'components/Paginate';
import CatalogListByCategory from '../CatalogListByCategory';
import CardsList from '../CardsList';
import CatalogSection from '../CatalogSection';

import { PAGE_RANGE_TO_DISPLAY } from '../../constants';
import { useCatalogContext } from '../../contexts/CatalogContext';
import { IconButtonProps } from '@just-ai/just-ui';

import styles from './styles.module.scss';

const iconProps: IconButtonProps[] = [{ name: 'farSearch', color: 'primary' }];

const CatalogContent = () => {
  const {
    updateQueryState,
    catalogServices,
    handlePageChange,
    isLoading,
    page,
    pageCount,
    nameFilter,
    taskTypeFilter,
  } = useCatalogContext();

  return (
    <div className={styles.catalogListPage__wrap}>
      <h1>{t('Catalog:CatalogTitle')}</h1>
      <div className={styles.catalogListPage__filtersContainer}>
        <SearchFilter
          className={styles.catalogListPage__search}
          dataTestId='CatalogPage:SearchByName'
          placeholder={t('CatalogPage:SearchByName')}
          value={nameFilter}
          setValue={value => updateQueryState('name', value)}
          skipSearchIcon={false}
          iconProps={iconProps}
        />
      </div>
      <div className={styles.catalogListPage__content}>
        <Spinner show={isLoading} centered />
        {!!nameFilter && !taskTypeFilter ? (
          <CardsList items={catalogServices} />
        ) : !taskTypeFilter ? (
          <CatalogListByCategory services={catalogServices} />
        ) : (
          <CatalogSection isActive category={taskTypeFilter} services={catalogServices} />
        )}
      </div>
      {pageCount !== undefined && pageCount > 1 && (
        <Paginate
          containerClassName={styles.catalogListPage__paginate}
          nextLabel={t('ServicesCatalog:Paginate:NextLabel')}
          previousLabel={t('ServicesCatalog:Paginate:PreviousLabel')}
          onPageChange={handlePageChange}
          forcePage={page}
          pageRangeDisplayed={PAGE_RANGE_TO_DISPLAY}
          pageCount={pageCount}
        />
      )}
    </div>
  );
};

export default React.memo(CatalogContent);
