import { ServiceTaskTypes } from 'contexts/ServicesContext/types';
import { RequestBodyMode } from 'modules/ServiceTestComponents/TestingForm/types';

export const getRequestBodyModeByServiceType = (serviceType?: ServiceTaskTypes) => {
  if (!serviceType) {
    return undefined;
  }
  switch (serviceType) {
    case 'text2vec':
    case 'text-classification':
    case 'ner':
    case 'text-processing':
      // case 'translation':
      // case 'summarization':
      return RequestBodyMode.text;
    // case 'text-generation':
    // case 'text-clustering':
    case 'image-generation':
    case 'custom':
      return RequestBodyMode.json;
    case 'tts':
      return RequestBodyMode.text;
    default:
      return undefined;
  }
};
