import { Inject, Service } from 'typedi';

import { ProcessApiService } from 'services/ApiServices/ProcessApiService';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { parseStringWithPrecision } from 'utils/numbers';

const DEFAULT_PREDICT_TIMEOUT = 30000;
const PREDICT_TIMEOUT_MARGIN = 10000;
const DECIMAL_PLACES = 2;

export enum ConfigDefinitionMode {
  selectExistingConfig = 'selectExistingConfig',
  writeConfig = 'writeConfig',
}

@Service()
export class PredictService {
  @Inject(() => ProcessApiService) processApi!: ProcessApiService;

  public async predict(
    accountId: number,
    modelId: number,
    dataToSend: any,
    shouldUseConfig: boolean,
    configMode: ConfigDefinitionMode,
    config: string,
    configId?: string,
    timeoutSec?: number,
    options?: any
  ) {
    const timeout = (timeoutSec ? timeoutSec * 1000 : DEFAULT_PREDICT_TIMEOUT) + PREDICT_TIMEOUT_MARGIN;
    let result: AxiosResponse<string, any>;
    if (!shouldUseConfig) {
      result = await this.processApi.predict(
        accountId.toString(),
        modelId.toString(),
        dataToSend,
        undefined,
        undefined,
        undefined,
        {
          timeout,
          ...options,
        }
      );
    } else if (configMode === ConfigDefinitionMode.selectExistingConfig) {
      const configIdToSend = configId !== undefined ? parseInt(configId) : undefined;
      result = await this.processApi.predict(
        accountId.toString(),
        modelId.toString(),
        dataToSend,
        configIdToSend,
        undefined,
        undefined,
        {
          timeout,
          ...options,
        }
      );
    } else {
      const parsedConfig = this.parseFormConfig(config);
      result = await this.processApi.predictWithConfig(
        accountId.toString(),
        modelId.toString(),
        { config: parsedConfig, data: dataToSend },
        undefined,
        {
          timeout,
          ...options,
        }
      );
    }

    const { price, time } = this.getPredictHeaderInfo(result.headers);
    return { data: result?.data, price, time };
  }

  private parseFormConfig(config: string) {
    let trimmedConfig = config.trim();
    return trimmedConfig ? JSON.parse(trimmedConfig) : null;
  }

  public getPredictHeaderInfo(
    headers?: AxiosResponseHeaders | Partial<Record<string, string> & { 'set-cookie'?: string[] | undefined }>
  ) {
    if (!headers) return { price: undefined, time: undefined };
    const price = parseStringWithPrecision(headers['z-billing-currency'] || '', DECIMAL_PLACES);
    const time = parseStringWithPrecision(headers['z-gateway-time'] || '', DECIMAL_PLACES);
    return { price, time };
  }
}
