import { Icon } from '@just-ai/just-ui/dist/Icon';
import React from 'react';
import styles from './styles.module.scss';

export type InfoBannerType = 'warning' | 'danger';

interface InfoBannerInterface {
  type?: InfoBannerType;
  message?: string | React.ReactNode;
  actionRender?: () => React.ReactNode;
  ['data-test-id']?: string;
}

const InfoBanner = ({ type = 'warning', message, actionRender, ...props }: InfoBannerInterface) => {
  return (
    <div className={styles[`banner-${type}`]} data-test-id={props?.['data-test-id']}>
      <Icon name='farExclamationCircle' color={type} size='lg' />
      <span>{message}</span>
      {actionRender?.()}
    </div>
  );
};

export default React.memo(InfoBanner);
