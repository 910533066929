import React from 'react';

import CardItemHead from './components/CardItemHead';

import { ModelInfoData, TrainingTypeEnum } from 'api/CailagateApi/api/client';
import { useAuthContext } from 'contexts/AuthContext';
import { StateToStatusMap } from 'types/models';

import styles from './styles.module.scss';

interface CardInterface {
  service: ModelInfoData;
  toggleFavorite: () => Promise<void>;
}

function Card({ service, toggleFavorite }: CardInterface) {
  const {
    fittable,
    shortDescription,
    modelName,
    favorite,
    state,
    trainingType,
    httpSettings: { isHttpEnabled },
    prototype,
  } = service;

  const showServiceStatus = !(fittable && trainingType === TrainingTypeEnum.SingleContainer);
  const { user } = useAuthContext();
  const { status } = state && showServiceStatus ? StateToStatusMap[state] : { status: undefined };

  return (
    <div className={styles.catalogItem}>
      <CardItemHead
        serviceId={modelName}
        showFavoriteToggle={!!user}
        isFavorite={favorite}
        fittable={fittable}
        isHttpEnabled={isHttpEnabled}
        toggleFavorite={toggleFavorite}
        status={status}
        prototype={prototype}
      />
      <div className={styles.catalogItem__text}>
        <p>{shortDescription}</p>
      </div>
    </div>
  );
}
export default React.memo(Card);
