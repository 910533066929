import { t } from 'localization';
import React from 'react';
import styles from './styles.module.scss';

const NoServicesImg = () => {
  return (
    <svg
      className={styles.noServices__img}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 0C23.8162 0 23.6316 0.0028125 23.4459 0.0084375C13.5947 0.30375 6 8.89875 6 18.7547V43.4972C6 44.4009 6.73969 45 7.51125 45C7.87969 45 8.25563 44.8631 8.56125 44.5575L10.8975 42.8203C11.1656 42.6216 11.4797 42.5241 11.7919 42.5241C12.2072 42.5241 12.6188 42.6956 12.9141 43.0284L16.9406 47.5612C17.2331 47.8538 17.6175 48.0009 18.0009 48.0009C18.3844 48.0009 18.7688 47.8547 19.0613 47.5612L22.8787 43.2628C23.1769 42.9272 23.5884 42.7584 24 42.7584C24.4116 42.7584 24.8231 42.9263 25.1213 43.2628L28.9387 47.5612C29.2312 47.8538 29.6156 48.0009 29.9991 48.0009C30.3825 48.0009 30.7669 47.8547 31.0594 47.5612L35.0859 43.0284C35.335 42.7476 35.6804 42.5705 36.0537 42.5319C36.4271 42.4934 36.8013 42.5963 37.1025 42.8203L39.4388 44.5575C39.7444 44.8631 40.1203 45 40.4888 45C41.2594 45 42 44.4009 42 43.4972V18C42 8.05875 33.9412 0 24 0ZM37.5 38.1628C37.08 38.0709 36.6478 38.0241 36.2091 38.0241C34.4953 38.0241 32.8603 38.7581 31.7222 40.0397L30 41.9784L28.4859 40.2741C27.3488 38.9934 25.7128 38.2584 24 38.2584C22.2872 38.2584 20.6522 38.9934 19.5141 40.2741L18 41.9784L16.2769 40.0387C15.7135 39.4053 15.0224 38.8983 14.2491 38.5511C13.4758 38.2039 12.6377 38.0242 11.79 38.0241C11.3512 38.0241 10.9191 38.0709 10.4991 38.1628V18.7547C10.4991 11.115 16.3678 4.72312 23.58 4.50656L24 4.5C31.4438 4.5 37.5 10.5562 37.5 18V38.1628ZM18 15C16.3434 15 15 16.3434 15 18C15 19.6566 16.3434 21 18 21C19.6566 21 21 19.6566 21 18C21 16.3434 19.6566 15 18 15ZM30 15C28.3434 15 27 16.3434 27 18C27 19.6566 28.3434 21 30 21C31.6566 21 33 19.6566 33 18C33 16.3434 31.6566 15 30 15Z'
        fill='#A6ABAF'
      />
    </svg>
  );
};

const NoServices = () => {
  return (
    <>
      <NoServicesImg />
      <h3 className={styles.noServices}>{t('Catalog:NoServices')}</h3>
    </>
  );
};

export default React.memo(NoServices);
