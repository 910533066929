import React, { useCallback, useEffect } from 'react';

import { useAuthContext } from 'contexts/AuthContext';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';
import { useLoading } from 'utils/hooks';
import { notifyActionMessage } from 'components/MessageContainer';
import { t } from 'localization';
import Spinner from 'components/Spinner';
import BalanceRefillForm, { useBalanceRefillForm } from '../BalanceRefillForm';
import ModalDefault from '@just-ai/just-ui/dist/ModalDefault';
import { BalanceRefillFormFieldsNamesList, defaultFormValues } from '../BalanceRefillForm/types';
import { useBalanceContext } from 'contexts/BalanceContext';
import { handleFormErrors } from 'utils/form';

import styles from './styles.module.scss';

interface BalanceRefillModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
}

const BalanceRefillModal = ({ isOpen, onClose, onResolve }: BalanceRefillModalInterface) => {
  const { user } = useAuthContext();
  const formMethods = useBalanceRefillForm([isOpen]);
  const { refillFromCard, refillFromIncomeBalance, checkIfUserIsBlocked, getBalance } = useBalanceContext();
  const [isLoading, , startLoading, endLoading] = useLoading();

  const submit = useCallback(async () => {
    if (!user) return;
    const formValues = formMethods.getValues();
    const { amountCurrency: amountCurrencyError } = formMethods.formState.errors;
    if (!!amountCurrencyError) return;

    startLoading();
    try {
      if (formValues.method === 'fromIncomeBalance') {
        const roundedSum = Math.floor(parseFloat(formValues.sum) * 100) / 100;
        await refillFromIncomeBalance(roundedSum);
        await checkIfUserIsBlocked();
        await getBalance();
        onResolve();
      } else {
        const amountCurrency = Math.floor(parseFloat(formValues.amountCurrency) * 100) / 100;
        // const selectedPackage = availableRefillPackages?.packages?.find(item => item.id === id);
        const response = await refillFromCard(amountCurrency, window.location.href);
        if (response?.paymentUrl) {
          window.location.href = response.paymentUrl;
        }
      }
    } catch (error) {
      handleFormErrors(error, BalanceRefillFormFieldsNamesList, formMethods.setError);
    } finally {
      endLoading();
    }
  }, [
    checkIfUserIsBlocked,
    endLoading,
    formMethods,
    getBalance,
    onResolve,
    refillFromCard,
    refillFromIncomeBalance,
    startLoading,
    user,
  ]);

  useEffect(() => {
    if (!isOpen) {
      formMethods.reset(defaultFormValues);
    }
  }, [formMethods, isOpen]);

  return (
    <ModalDefault
      title={t('BalanceRefillModal:BalanceRefillTitle')}
      className={styles.balanceRefillModal}
      isOpen={isOpen}
      disableActionButtonAutoFocus
      size='md'
      buttonSubmitText={t('BalanceRefillModal:RequestAccess')}
      buttonCancelText={t('BalanceRefillModal:Cancel')}
      onClickCloseIcon={onClose}
      onActionClick={submit}
      onCancelClick={onClose}
    >
      <Spinner centered show={isLoading} />
      <BalanceRefillForm formMethods={formMethods} />
    </ModalDefault>
  );
};

export default React.memo(BalanceRefillModal);

export const useBalanceRefillModal = () => {
  return usePromisifyComponent(
    (onResolve, onClose, opened) => <BalanceRefillModal isOpen={opened} onClose={onClose} onResolve={onResolve} />,
    {
      onResolve: () =>
        notifyActionMessage(t('BalanceRefillModal:RequestAccessSuccessMessage'), { theme: 'dark', type: 'success' }),
    }
  );
};
