import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { history } from 'App';

const useQueryState = <VALUE_TYPE extends string>(
  params: readonly VALUE_TYPE[],
  initialize?: (initValue: Partial<Record<VALUE_TYPE, string>>) => Promise<Partial<Record<VALUE_TYPE, string>>>
) => {
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);

  const getQueryURLSearchParams = useCallback(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const getQueryMap = useCallback(() => {
    const query = getQueryURLSearchParams();
    return params.reduce((queries, name) => {
      const currentQuery = query.get(name) || undefined;
      if (currentQuery) {
        queries[name] = currentQuery;
      }
      return queries;
    }, {} as Partial<Record<VALUE_TYPE, string>>);
  }, [getQueryURLSearchParams, params]);

  const queryMap = useMemo(() => getQueryMap(), [getQueryMap]);

  const updateQuery = useCallback(
    (key: VALUE_TYPE, value: string) => {
      let searchParams = getQueryURLSearchParams();

      if (!value) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }

      history.push({ search: searchParams.toString() });
    },
    [getQueryURLSearchParams]
  );

  const updateQueryWithRecord = useCallback(
    (queryRecord: Partial<Record<VALUE_TYPE, string>>) => {
      let searchParams = getQueryURLSearchParams();
      Object.entries(queryRecord).forEach(([key, value]) => {
        if (!value) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value as string);
        }
      });
      history.push({ search: searchParams.toString() });
    },
    [getQueryURLSearchParams]
  );

  useEffect(() => {
    if (isInitialized || !initialize) return;
    (async () => {
      const updatedQueryMap = await initialize(queryMap);
      updateQueryWithRecord(updatedQueryMap);
      setIsInitialized(true);
    })();
  }, [initialize, isInitialized, queryMap, updateQueryWithRecord]);

  return {
    isInitialized,
    queryState: queryMap,
    updateQueryState: updateQuery,
    updateQueryStateWithRecord: updateQueryWithRecord,
  };
};

export default useQueryState;
