import { Inject, Service } from 'typedi';

import { ProcessApiService } from 'services/ApiServices/ProcessApiService';
import { PredictService } from 'services/PredictService';
import { ASRTestingFormFieldsType, AudioCreationMode } from './types';
import { PredictConfigData } from 'api/CailagateApi/api/client';
import { encodeBlobToBase64 } from 'utils';
import { AxiosResponseHeaders } from 'axios';

@Service()
export class ASRTestingFormService {
  @Inject(() => ProcessApiService) processApi!: ProcessApiService;
  @Inject(() => PredictService) predictService!: PredictService;
  static storageKey = 'asr-testing-form-data';

  public getConfigsSelectItem(config: PredictConfigData) {
    return {
      label: config.name,
      value: config?.id?.configId.toString() || '0',
    };
  }

  public getConfigsSelectList(configs?: PredictConfigData[]) {
    if (!configs?.length) return undefined;
    return configs.map(this.getConfigsSelectItem);
  }

  public async submitTest(
    accountId: number,
    modelId: number,
    formValues: ASRTestingFormFieldsType,
    timeoutSec?: number,
    options?: any
  ) {
    const {
      config,
      configId,
      configMode,
      audioCreationMode,
      audio,
      audioFile,
      requestJson,
      shouldSetConfig: shouldUseConfig,
    } = formValues;
    let encodedAudio: string = '';
    let dataToSend: object | undefined;
    if (audioCreationMode === AudioCreationMode.record && !!audio) {
      encodedAudio = await encodeBlobToBase64(audio);
      dataToSend = { audio_base64: encodedAudio };
    } else if (audioCreationMode === AudioCreationMode.upload && !!audioFile) {
      encodedAudio = await encodeBlobToBase64(new Blob([audioFile], { type: 'audio/wav' }));
      dataToSend = { audio_base64: encodedAudio };
    } else if (audioCreationMode === AudioCreationMode.json && !!requestJson) {
      dataToSend = JSON.parse(requestJson);
    }
    return this.predictService.predict(
      accountId,
      modelId,
      dataToSend,
      shouldUseConfig,
      configMode,
      config,
      configId,
      timeoutSec,
      options
    );
  }

  public getTestResponseHeaderInfo(
    headers?: AxiosResponseHeaders | Partial<Record<string, string> & { 'set-cookie'?: string[] | undefined }>
  ) {
    return this.predictService.getPredictHeaderInfo(headers);
  }
}
