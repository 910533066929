import { UseFormReturn } from 'react-hook-form';

export type CloneFormMethodsType = UseFormReturn<CloneFormFieldsType, any>;

export enum CloneFormFieldsNames {
  name = 'name',
}

export const CloneFormFieldsNamesList = Object.values(CloneFormFieldsNames);

export type CloneFormFieldsType = {
  [CloneFormFieldsNames.name]: string;
  commonError: any;
};
export const defaultFormValues: CloneFormFieldsType = {
  [CloneFormFieldsNames.name]: '',
  commonError: undefined,
};
