import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { history } from 'App';

import { t } from 'localization';

import Spinner from 'components/Spinner';
import IconLink from 'components/IconLink';
import Header from './components/Header';
import Content from './components/Content';

import { useAppContext, useDI } from 'contexts/AppContext';

import WidgetStub from './components/WidgetStub';
import ServiceStatusBar from './components/ServiceStatusBar';

import { ModelInfoData, ModelShortStatusData, TrainingTypeEnum } from 'api/CailagateApi/api/client';
import { ModelApiService } from 'services/ApiServices/ModelApiService';
import { CATALOG_BASE_PATH } from 'modules/ServicesCatalog/routesConfig';
import { AbortControllerService } from 'services/AbortControllerService';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';
import { AppLogger } from 'services/AppLogger';

import { useAuthContext } from 'contexts/AuthContext';
import { useLoading } from 'utils/hooks';
import useLongPolling from 'hooks/useLongPolling';

import { getWidgetComponent } from './utils';

import styles from './styles.module.scss';

const LONG_POLLING_INTERVAL = 2000;

const ServicePage = function () {
  const modelApi = useDI(ModelApiService);
  const abortController = useDI(AbortControllerService);

  const { user } = useAuthContext();
  const { canonicalHref, isTovieMLP } = useAppContext();

  const [serviceData, setServiceData] = useState<ModelInfoData | undefined>();
  const [modelShortStatus, setModelShortStatus] = useState<ModelShortStatusData | undefined>();

  const { account, service } = useParams<{ service: string; account: string }>();
  const [isLoading, , startLoading, endLoading] = useLoading();

  const updateModelShortStatus = useCallback(async () => {
    if (!serviceData) return;
    try {
      const { data } = await modelApi.shortModelStatus(
        serviceData.id.accountId.toString(),
        serviceData.id.modelId.toString()
      );
      setModelShortStatus(data);
    } catch (error: any) {
      AppLogger.error({ exception: error }, { useThrottle: true });
    }
  }, [modelApi, serviceData]);

  const [isInitialized, setIsInitialized] = useState(false);

  useLongPolling(updateModelShortStatus, LONG_POLLING_INTERVAL);

  useEffect(() => {
    updateModelShortStatus().finally(() => setIsInitialized(true));
  }, [updateModelShortStatus]);

  const startService = useCallback(async () => {
    const instanceStatus = modelShortStatus?.instances;
    if (!serviceData) return;
    if (instanceStatus === undefined || instanceStatus.internalRequested !== 0) return;
    try {
      const { id } = serviceData;
      await modelApi.startSingleInstance(id.accountId.toString(), id.modelId.toString());
      await updateModelShortStatus();
    } catch (error: any) {
      AppLogger.error({ exception: error });
    }
  }, [modelApi, modelShortStatus?.instances, serviceData, updateModelShortStatus]);

  const redirect = useMemo(() => {
    const from = (history.location.state as { from?: string })?.from;
    return from ? from : `/${CATALOG_BASE_PATH}`;
  }, []);

  const setFavourite = useCallback(
    async (serviceAccountId: number, serviceId: number, favorite: boolean) => {
      if (!serviceData || !user) return;
      const prevCurrentItems = serviceData;
      const updatedCurrentItem = { ...serviceData, favorite };
      setServiceData(updatedCurrentItem);
      abortController
        .get(serviceId.toString(), signal =>
          modelApi.setFavorite(serviceAccountId.toString(), serviceId.toString(), favorite, undefined, { signal })
        )
        .catch(error => {
          if (!axios.isCancel(error)) {
            setServiceData(prevCurrentItems);
          }
        });
    },
    [abortController, modelApi, serviceData, user]
  );

  const getServiceData = useCallback(async () => {
    startLoading();
    try {
      const { data: modelInfo } = await modelApi.getModelInfo(account, service);
      setServiceData(modelInfo);
    } catch (error: any) {
      AppLogger.error({ exception: error });
    }
    endLoading();
  }, [account, endLoading, modelApi, service, startLoading]);

  useEffect(() => {
    getServiceData();
  }, [getServiceData]);

  if (isLoading || !isInitialized || !serviceData) return <Spinner show />;

  const isPrototype = serviceData.prototype;

  const WidgetComponent = getWidgetComponent(
    serviceData.fittable,
    serviceData.taskType as ServiceTaskTypes,
    serviceData.httpSettings.isHttpEnabled,
    isPrototype
  );

  const showWidgetStub = !user && (serviceData.fittable || !serviceData.publicSettings.publicTestingAllowed);
  const showServiceStatus =
    !(serviceData.fittable && serviceData.trainingType === TrainingTypeEnum.SingleContainer) && !isPrototype;

  return (
    <>
      <Helmet>
        {serviceData.shortDescription && (
          <meta name='description' content={serviceData.shortDescription} data-react-helmet='true' />
        )}
        <title>{`${account}/${service} · ${isTovieMLP ? 'Tovie ML Place' : 'Caila'}`}</title>
        {canonicalHref && <link rel='canonical' href={`${canonicalHref}/${CATALOG_BASE_PATH}/${account}/${service}`} />}
      </Helmet>
      <div className={styles.servicePage__wrapper}>
        <div className={styles.servicePage__leftSide}>
          <div className={styles.servicePage__elementsContainer}>
            <div className={styles.servicePage__header}>
              <IconLink
                to={redirect}
                icon='faArrowLeft'
                text={t('ServicePage:Back')}
                dataTestId='servicePageBackButton'
              />
              <Header
                service={serviceData}
                toggleFavourite={() =>
                  setFavourite(serviceData.id.accountId, serviceData.id.modelId, !serviceData.favorite)
                }
                showToggleFavorite={!!user}
              />
            </div>
            <Content />
          </div>
        </div>
        <div className={styles.servicePage__rightSide}>
          {showServiceStatus && (
            <ServiceStatusBar
              shortStatus={modelShortStatus}
              startService={startService}
              defaultServiceState={serviceData.state}
            />
          )}
          {showWidgetStub ? (
            <WidgetStub type={serviceData.fittable ? 'fit' : 'test'} />
          ) : (
            <WidgetComponent serviceData={serviceData} />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(ServicePage);
