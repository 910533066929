import { Service } from 'typedi';

import { ModelEndpointApi } from 'api/CailagateApi/api/client';
import { ServiceTaskTypes } from 'contexts/ServicesContext/types';

@Service()
export class ModelApiService extends ModelEndpointApi {
  constructor() {
    super({ baseOptions: { timeout: 30000 } }, '');
  }

  async getCatalogModels(
    accountId?: string,
    name?: string,
    taskType?: ServiceTaskTypes,
    page?: number,
    onlyPublicModels: boolean = true,
    itemsPerPage: number = 1000,
    options?: any
  ) {
    return accountId
      ? this.getPagedModels(
          accountId,
          undefined,
          undefined,
          undefined,
          name,
          onlyPublicModels,
          undefined,
          taskType,
          undefined,
          undefined,
          undefined,
          page,
          itemsPerPage,
          undefined,
          undefined,
          options
        )
      : this.getPublicModels(
          undefined,
          undefined,
          undefined,
          name,
          onlyPublicModels,
          undefined,
          taskType,
          undefined,
          undefined,
          undefined,
          page,
          itemsPerPage,
          undefined,
          undefined,
          options
        );
  }
}
